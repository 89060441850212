import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Placeholder } from "rsuite";
import Fixture from "./components/Fixture/Fixture";
import Leaderboard from "./components/Leaderboard/Leaderboard";
import { useQuery } from "@tanstack/react-query";
import Cup from "./components/Cup/Cup";
import { getTournament } from "../../services/tournament";
import ShowdownDetail from "./components/Showdown/Showdown";
import { Showdown } from "../../interfaces/showdown";
import Gallery from "./components/Gallery/Gallery";

const TournamentDetail = () => {
  const { id } = useParams();
  const [showdown, setShowdown] = useState<Showdown | null>(null);
  const [journeyId, setJourneyId] = useState<number | null>(null);
  const { data, isLoading } = useQuery(["tournaments", id], () => getTournament(Number(id)), {
    enabled: !!id,
  });

  const handleChangeJourneyId = (journeyId: number) => {
    setJourneyId(journeyId);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading)
    return (
      <div className="page-container">
        <Placeholder.Paragraph style={{ margin: "50px 0" }} />
        <Placeholder.Grid rows={5} columns={4} active />
        <Placeholder.Paragraph style={{ margin: "50px 0" }} />
      </div>
    );

  if (!data) return null;

  const renderTournamentFormat = () => {
    const tournamentId = Number(id);
    if (data.type === "LG") {
      return (
        <>
          <Leaderboard tournamentId={tournamentId} />
          <Fixture
            tournamentId={tournamentId}
            onShowdownClicked={setShowdown}
            onChangeJourney={handleChangeJourneyId}
            journeyId={data.last_journey_id}
          />
          <Gallery journeyId={journeyId || data.last_journey_id} />
        </>
      );
    }

    if (data.type === "CP") {
      return (
        <>
          <Cup tournamentId={tournamentId} onShowdownClicked={setShowdown} />
          <Gallery
            journeyId={journeyId || data.last_journey_id}
            tournamentId={tournamentId}
            showJourneysSelector
            onChangeJourney={handleChangeJourneyId}
          />
        </>
      );
    }

    if (data.type === "MX") {
      return (
        <>
          <Leaderboard tournamentId={tournamentId} />
          <Fixture
            tournamentId={tournamentId}
            onChangeJourney={handleChangeJourneyId}
            onShowdownClicked={setShowdown}
            journeyId={data.last_journey_id}
          />
          <Gallery journeyId={journeyId || data.last_journey_id} />
          <Cup tournamentId={tournamentId} onShowdownClicked={setShowdown} showTitle />
        </>
      );
    }

    return null;
  };

  return (
    <div className="page-container">
      <h1>{data.name}</h1>
      <br />
      {renderTournamentFormat()}
      <ShowdownDetail
        open={showdown !== null}
        showdown={showdown!}
        onClose={() => setShowdown(null)}
      />
    </div>
  );
};

export default TournamentDetail;
