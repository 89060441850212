import { useParams } from "react-router-dom";
import "./playerInfo.less";
import { useQuery } from "@tanstack/react-query";
import { CACHE_TIME_10_MINUTES } from "../../utils/constants";
import { getPlayerStats } from "../../services/player";
import { calculateAge } from "../../utils/calculateAge";
import { Player } from "../../interfaces/player";
import { LastResults } from "./components/LastResults/LastResults";
import { Stats } from "./components/Stats/Stats";
import { Col, Grid, Row, Placeholder } from "rsuite";
import { useEffect } from "react";

const PlayerInfo = () => {
  const { id } = useParams();

  const query = useQuery(["player", Number(id)], () => getPlayerStats(Number(id)), {
    enabled: !!id,
    staleTime: CACHE_TIME_10_MINUTES,
  });


  const renderItemPlayerData = (label: string, value: string | number) => {
    return (
      <li className="li-information">
        <span className="info-information">{label}</span>
        <span className="value-information">{value}</span>
      </li>
    );
  };

  const renderPlayerData = (player: Player | undefined) => {
    return (
      <div className="body-information">
        <ul className="ul-information">
          {renderItemPlayerData("Nombre", player?.name || "-")}
          {renderItemPlayerData("Apodo", player?.nickname || "-")}
          {renderItemPlayerData("Edad", calculateAge(player?.date_of_birth) || "-")}
          {renderItemPlayerData(
            "Mano hábil",
            !player?.skillful_hand ? "-" : player?.skillful_hand === "L" ? "Izq" : "Der"
          )}
        </ul>
        <div className="line"></div>
        <ul className="ul-information">
          {renderItemPlayerData("Club", player?.club?.name || "-")}
          {renderItemPlayerData("Ranking", player?.order !== undefined ? player?.order + 1 : "-")}
          {renderItemPlayerData("Teléfono", player?.cell_phone_number || "-")}
          {renderItemPlayerData("Email", player?.email || "-")}
        </ul>
      </div>
    );
  };

  const renderUnbeatenStreak = (unbeaten_streak: number | undefined) => {
    return (
      <div className="unbeaten_streak-background">
        <div className="unbeaten_streak">
          <div className="number-unbeaten_streak">{unbeaten_streak}</div>
          {!query.isFetching && (
            <h3>
              <span> Máxima racha de</span> <br /> victorias consecutivas
            </h3>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.scrollTo(0,0)
  }, [id])

  return (
    <div className="page-container">
      {query.isFetching ? (
        <Grid style={{ width: "100% ", marginTop: 32 }}>
          <Row gutter={24}>
            <Col xs={24} md={10} lg={8} style={{ marginBottom: 16 }}>
              <div className="image-container" style={{ padding: 0 }}>
                <Placeholder.Graph
                  active
                  height={260}
                  width={260}
                  style={{ borderRadius: "50%", minWidth: 260 }}
                />
              </div>
            </Col>
            <Col xs={24} md={14} lg={16} style={{ marginBottom: 16 }}>
              <Placeholder.Graph active height={260} style={{ borderRadius: 16 }} />
            </Col>
          </Row>
        </Grid>
      ) : (
        <div className="personal-information">
          <div className="image-container">
            <img
              className="image"
              src={query.data?.player?.picture || query.data?.player.club?.logo}
              alt=""
            />
          </div>
          <div className="player-information">
            <div>
              <h2 className="title-information">Datos del jugador</h2>
              {renderPlayerData(query?.data?.player)}
            </div>
          </div>
        </div>
      )}
      <div>
        <Stats isFetching={query.isFetching} playerStats={query.data} />
        {renderUnbeatenStreak(query.data?.unbeaten_streak)}
      </div>
      <LastResults
        playerId={query.data?.player?.id}
        isFetching={query?.isFetching}
        lastResults={query.data?.last_results}
      />
    </div>
  );
};

export default PlayerInfo;
