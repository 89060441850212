import { Pie, Line } from "react-chartjs-2";
import { StatsProps } from "./types";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import "./stats.less";
import { Col, Grid, Row, Placeholder } from "rsuite";
import { TeamsStats } from "../../../../interfaces/teamStats";
import { getTeamStats } from "../../../../utils/stats";
import useResponsive from "../../../../hooks/useResponsive";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

const chartConfig = {
  responsive: true,
  maintainAspectRatio: false,
};

const COLORS = {
  won: "#00bcd4",
  lost: "#A7261C",
};

export const Stats = ({ teamStats, isFetching }: StatsProps) => {
  const { isMobile } = useResponsive(780);

  const renderItemStats = (label: string, value: string | number, color?: string) => {
    return (
      <li className="li-information-stats">
        <div className="stats-information-bullet" style={{ backgroundColor: color }}></div>
        <span className="info-information legend">{label}</span>
        <span className="value-information">{value}</span>
      </li>
    );
  };

  const renderStats = (team: TeamsStats | undefined, field: "showdowns" | "matches") => {
    if (!team) return null;

    const stats = getTeamStats(team, field);
    const label = field === "showdowns" ? "Encuentros" : "Partidos";
    const labels = [`${label} Perdidos`, `${label} Ganados`];
    const data = {
      datasets: [
        {
          labels: labels,
          data: [stats.lost, stats.won],
          borderColor: "#33373d",
          backgroundColor: [COLORS.lost, COLORS.won],
        },
      ],
    };
    return (
      <div className="pie">
        <div className="pie-chart-container">
          <Pie className="pie-chart" options={chartConfig} data={data} />
        </div>
        <div className="stats-information">
          <ul>
            {renderItemStats(`${label} ganados: `, stats.won ?? "-", COLORS.won)}
            {renderItemStats(`${label} perdidos: `, stats.lost ?? "-", COLORS.lost)}
            {renderItemStats(`${label} jugados: `, stats.played ?? "-")}
          </ul>
        </div>
      </div>
    );
  };

  const renderLastTournamentsStats = (team: TeamsStats | undefined) => {
    if (!team) return null;

    const { positions, labels, numberOfTeams } = team.tournaments.reduce<{
      positions: number[];
      labels: string[];
      numberOfTeams: number[]
    }>(
      (acc, curr) => ({
        ...acc,
        numberOfTeams: [...(acc.numberOfTeams || []), curr.number_of_teams],
        positions: [...(acc.positions || []), curr.position],
        labels: [...(acc.labels || []), curr.tournament.name],
      }),
      { positions: [], labels: [], numberOfTeams: [] }
    );

    const maxNumberOfTeams = Math.max(...numberOfTeams)

    const data = {
      labels: labels,
      datasets: [
        {
          data: positions,
          fill: false,
          borderColor: COLORS.won,
          tension: 0,
        },
      ],
    };

    return (
      <div className="line-chart-card">
        <Line
          options={{
            ...chartConfig,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                reverse: true,
                display: true,
                title: {
                  text: "Posiciones",
                  display: true,
                  color: "#fff",
                  font: {
                    size: 20,
                  },
                },
                min: 1,
                max: maxNumberOfTeams,
                ticks: {
                  color: "#d3cece",
                  font: {
                    size: 18,
                  },
                  stepSize: 1,
                },
              },
              x: {
                ticks: {
                  color: "#d3cece",
                  font: {
                    size: 16,
                  },
                  padding: 20,
                  display: !isMobile,
                },
                title: {
                  text: "Torneos",
                  display: true,
                  color: "#fff",
                  font: {
                    size: 20,
                  },
                },
              },
            },
          }}
          data={data}
        />
      </div>
    );
  };

  if (isFetching) {
    return (
      <Grid style={{ width: "100% ", marginTop: 48 }}>
        <Row gutter={24}>
          <Col xs={24} md={12} style={{ marginBottom: 16 }}>
            <Placeholder.Graph active height={330} style={{ borderRadius: 16 }} />
          </Col>
          <Col xs={24} md={12} style={{ marginBottom: 16 }}>
            <Placeholder.Graph active height={330} style={{ borderRadius: 16 }} />
          </Col>
        </Row>
        <Row gutter={24} style={{ marginTop: 32 }}>
          <Col xs={24}>
            <Placeholder.Graph active height={330} style={{ borderRadius: 16 }} />
          </Col>
        </Row>
      </Grid>
    );
  }

  return (
    <>
      <div className="stats">
        <div>
          <div className="title-stats">
            <h2>Encuentros</h2>
          </div>
          {renderStats(teamStats, "showdowns")}
        </div>
        <div>
          <div className="title-stats">
            <h2>Partidos</h2>
          </div>
          {renderStats(teamStats, "matches")}
        </div>
      </div>
      <div>
        <h2 className="line-chart-title">Últimos torneos</h2>
        {renderLastTournamentsStats(teamStats)}
      </div>
    </>
  );
};
