import { useQuery } from "@tanstack/react-query";
import { getJourneyFiles } from "../../../../services/gallery";
import { CACHE_TIME_10_MINUTES } from "../../../../utils/constants";
import { useState } from "react";
import { getTournamentJourneys } from "../../../../services/tournament";

export const useGallery = (tournamentId: number | undefined, journeyId: number, showJourneysSelector?: boolean) => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [imageIndexFullScreen, setImageIndexFullScreen] = useState(-1);

  const galleryQuery = useQuery(
    ["journey", journeyId, "gallery"],
    () => getJourneyFiles(journeyId),
    {
      enabled: !!journeyId,
      staleTime: CACHE_TIME_10_MINUTES,
    }
  );

  const journeysQuery = useQuery(
    ["tournaments", tournamentId, "journeys"],
    () => tournamentId ? getTournamentJourneys(tournamentId) : null,
    {
      staleTime: CACHE_TIME_10_MINUTES,
      enabled: !!tournamentId && showJourneysSelector,
    }
  );

  const handleImageClick = (index: number) => {
    setShowFullScreen(true);
    setImageIndexFullScreen(index);
  };
  const handleCloseFullScreen = () => {
    setShowFullScreen(false);
    setTimeout(() => setImageIndexFullScreen(-1), 300);
  };

  return {
    journeysQuery,
    galleryQuery,
    imageIndexFullScreen,
    showFullScreen,
    handleImageClick,
    handleCloseFullScreen,
  };
};
