import TournamentCard from "../../../../components/TournamentCard/TournamentCard";
import { useTournaments } from "../../../../hooks/useTournaments";
import "./activeTournaments.less";

const ActiveTournaments = () => {
  const { activeTournaments } = useTournaments();

  if (activeTournaments?.length === 0) return null;

  return (
    <div className="page-container active-tournaments-container">
      <h1 className="active-tournaments-title">Torneos actuales</h1>
      <h4 className="active-tournaments-subtitle">
        Consultá toda la información de nuestros torneos
      </h4>
      <div className="active-tournaments-cards-container">
        {activeTournaments
          ?.filter((t) => t.status === "OG")
          .map((tournament) => (
            <TournamentCard tournament={tournament} />
          ))}
      </div>
    </div>
  );
};

export default ActiveTournaments;
