import { PlayersH2H, TeamsH2H } from "../interfaces/h2h";
import { Player } from "../interfaces/player";
import { Team } from "../interfaces/team";
import { api } from "./api";

export const getPlayersH2H = async (
  playerOneId: number,
  playerTwoId: number
) => {
  const { data } = await api.get<PlayersH2H>(
    `/api/v1/players/head_to_head?player_one_id=${playerOneId}&player_two_id=${playerTwoId}`
  );

  return data;
};

export const getPlayersByString = async (searchPlayer: string) => {
  const { data } = await api.get<Player[]>(
    `/api/v1/players?search=${searchPlayer}`
  );
  return data;
};

export const getTeamsH2H = async (teamOneId: number, teamTwoId: number) => {
  const { data } = await api.get<TeamsH2H>(
    `/api/v1/teams/head_to_head?team_one_id=${teamOneId}&team_two_id=${teamTwoId}`
  );

  return data;
};

export const getTeamsByString = async (searchTeam: string) => {
  const { data } = await api.get<Team[]>(`/api/v1//teams?search=${searchTeam}`);
  return data;
};
