import { useEffect, useRef, useState } from "react";
import { OptionType, UseAutocompleteParams } from "./types";

const KEY_CODES = {
  DOWN: 40,
  UP: 38,
  PAGE_DOWN: 34,
  ESCAPE: 27,
  PAGE_UP: 33,
  ENTER: 13,
};

export default function useAutoComplete({
  delay = 500,
  options,
  value,
  onSearch,
  onSelect,
}: UseAutocompleteParams) {
  const manualSearchRef = useRef(false);
  const [myTimeout, setMyTimeOut] = useState<NodeJS.Timeout | number>(setTimeout(() => {}, 0));
  const listRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [textValue, setTextValue] = useState("");
  const [searchMode, setSearchMode] = useState(true);

  function delayInvoke(cb: Function) {
    if (myTimeout) {
      clearTimeout(myTimeout);
    }
    setMyTimeOut(setTimeout(cb, delay));
  }

  // function selectOption(index: number) {
  //   if (index > -1) {
  //     onSelect(options[index]);
  //     setTextValue(options[index].name);
  //   }
  //   // clearSuggestions();
  // }

  async function getSuggestions(searchTerm: string) {
    if (searchTerm && onSearch) {
      onSearch(searchTerm);
    }
  }

  //   function clearSuggestions() {
  //     setSuggestions([]);
  //     setSelectedIndex(-1);
  //   }

  function onTextChange(searchTerm: string) {
    setTextValue(searchTerm);
    // clearSuggestions();
    delayInvoke(() => {
      getSuggestions(searchTerm);
    });
  }

  const optionHeight = listRef?.current?.children[0]?.clientHeight || 0;

  function scrollUp() {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
    if (listRef.current) listRef.current.scrollTop -= optionHeight;
  }

  function scrollDown() {
    if (selectedIndex < options.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
    if (listRef.current) listRef.current.scrollTop = selectedIndex * optionHeight;
  }

  function pageDown() {
    setSelectedIndex(options.length - 1);
    if (listRef.current) listRef.current.scrollTop = options.length * optionHeight;
  }

  function pageUp() {
    setSelectedIndex(0);
    if (listRef.current) listRef.current.scrollTop = 0;
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const keyOperation = {
      [KEY_CODES.DOWN]: scrollDown,
      [KEY_CODES.UP]: scrollUp,
      // [KEY_CODES.ENTER]: () => selectOption(selectedIndex),
      [KEY_CODES.ESCAPE]: () => setSearchMode(false),
      [KEY_CODES.PAGE_DOWN]: pageDown,
      [KEY_CODES.PAGE_UP]: pageUp,
    };
    if (keyOperation[e.keyCode]) {
      keyOperation[e.keyCode]();
    } else {
      setSelectedIndex(-1);
    }
  }

  const handleSearchMode = (searchMode: boolean) => () => {
    setSearchMode(searchMode);
    setTextValue("");
    if (searchMode) {
      setTimeout(() => {
        if (inputRef.current) inputRef.current.focus();
      }, 100);
    }
  };

  const handleSelectOption = (option: OptionType) => () => {
    manualSearchRef.current = true;
    onSelect(option);
    setTextValue(option.name);
    setTimeout(() => {
      setSearchMode(false);
    }, 100);
  };

  useEffect(() => {
    if(!value) setTextValue("");
    if(value && !manualSearchRef.current) {
      manualSearchRef.current = true;
      setSearchMode(false);
    }
  }, [value])

  return {
    bindInput: {
      ref: inputRef,
      value: textValue,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => onTextChange(e.target.value),
      onFocus: () => setSearchMode(true),
      // onBlur: () => setTimeout(() => setSearchMode(false), 100),
      onKeyDown,
    },
    bindOptions: {
      ref: listRef,
    },
    selectedIndex,
    searchMode,
    handleSelectOption,
    handleSearchMode,
  };
}
