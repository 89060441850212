import { FaInstagram } from "react-icons/fa";
import "./footer.less";
import { Link } from "react-router-dom";
import { IconButton } from "rsuite";

const Footer = () => {
  const handleInstagram = () => {
    window.open("https://www.instagram.com/interclubes.squash/", "_blank")
  }

  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="footer-content-copyright">
          <p>2023 © Brup desarrollos. Todos los derechos reservados.</p>
        </div>
        <div className="footer-content-social">
        <Link to="/torneos">Torneos</Link>
        <Link to="/clubes">Clubes</Link>
        <IconButton className="instagram" icon={<FaInstagram />} appearance="primary" onClick={handleInstagram} />                  
        </div>
      </div>
    </div>
  );
};

export default Footer;
