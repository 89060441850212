import "./club.less";
import { useQuery } from "@tanstack/react-query";
import { getClub } from "../../services/clubs";
import { Table, TagGroup, Tag, Placeholder } from "rsuite";
import { useParams } from "react-router-dom";
import { CACHE_TIME_10_MINUTES } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { Player } from "../../interfaces/player";
import { Team } from "../../interfaces/team";
const { Column, HeaderCell, Cell } = Table;

const ClubDetail = () => {
  const { id } = useParams();
  const query = useQuery(["clubs", Number(id)], () => getClub(Number(id)), {
    enabled: !!id,
    staleTime: CACHE_TIME_10_MINUTES,
  });

  const navigate = useNavigate();
  const handleRowClickTeam = (team: Team) => navigate(`/equipos/${team.id}`);
  const handleRowClick = (player: Player) => navigate(`/jugadores/${player.id}`);

  const ImageCell = ({ rowData, dataKey, ...props }: any) => (
    <Cell {...props} style={{ padding: 0 }}>
      <div className="player-image-container">
        <img
          src={rowData.picture || query.data?.logo}
          className="player-image"
          alt={rowData.name}
        />
      </div>
    </Cell>
  );

  return (
    <div className="page-container">
      <div className="club-container">
        {query.isLoading ? (
          <Placeholder.Paragraph graph="image" rows={2} active style={{ width: "100%" }} />
        ) : (
          <>
            <div className="club-logo-container">
              <img
                src={query.data?.logo}
                alt={query.data?.name || "club-logo"}
                className="club-logo"
              />
            </div>
            <div className="club-information">
              <h2 className="club-name">{query.data?.name}</h2>
              <TagGroup>
                <Tag color="blue">
                  <span className="club-tag">{query.data?.teams?.length || 0}</span>equipos
                </Tag>
                <Tag color="blue">
                  <span className="club-tag">{query.data?.players?.length || 0}</span>jugadores
                </Tag>
              </TagGroup>
            </div>
          </>
        )}
      </div>
      <h4 className="club-subtitle">Equipos</h4>
      <Table
        autoHeight
        data={query.data?.teams || []}
        headerHeight={56}
        rowHeight={44}
        onRowClick={(row) => handleRowClickTeam(row as Team)}
        className="custom-table"
        loadAnimation
        loading={query.isFetching}
        locale={{
          emptyMessage: "No se encontraron resultados",
          loading: "Cargando...",
        }}
      >
        <Column minWidth={160} fixed flexGrow={1}>
          <HeaderCell>Equipo</HeaderCell>
          <Cell dataKey="name" />
        </Column>
        <Column minWidth={180} flexGrow={1}>
          <HeaderCell>Sede</HeaderCell>
          <Cell dataKey="campus.name" />
        </Column>
        <Column minWidth={180} flexGrow={1}>
          <HeaderCell>Capitán</HeaderCell>
          <Cell dataKey="captain.fullname" />
        </Column>
        <Column width={240} flexGrow={1}>
          <HeaderCell>Torneo actual</HeaderCell>
          <Cell dataKey="tournament.name" />
        </Column>
      </Table>
      <h4 className="club-subtitle">Jugadores</h4>
      <Table
        autoHeight
        data={(query.data?.players || []).map((t) => ({ ...t, position: (t.order || 0) + 1 }))}
        headerHeight={56}
        onRowClick={(row) => handleRowClick(row as Player)}
        rowHeight={44}
        className="custom-table"
        loadAnimation
        loading={query.isFetching}
        locale={{
          emptyMessage: "No se encontraron resultados",
          loading: "Cargando...",
        }}
      >
        <Column width={40} align="center" fixed>
          <HeaderCell width={40} style={{ justifyContent: "center" }}>
            #
          </HeaderCell>
          <Cell dataKey="position" />
        </Column>
        <Column width={48} align="center" fixed>
          <HeaderCell>{""}</HeaderCell>
          <ImageCell dataKey="picture" />
        </Column>
        <Column minWidth={160} fixed flexGrow={2}>
          <HeaderCell>Jugador</HeaderCell>
          <Cell dataKey="name" />
        </Column>
        <Column minWidth={140} flexGrow={1}>
          <HeaderCell>Apodo</HeaderCell>
          <Cell dataKey="nickname" />
        </Column>
        <Column minWidth={140} flexGrow={1}>
          <HeaderCell>Fecha de Nacimiento</HeaderCell>
          <Cell dataKey="date_of_birth" />
        </Column>
        <Column minWidth={140} flexGrow={1}>
          <HeaderCell>Teléfono</HeaderCell>
          <Cell dataKey="cell_phone_number" />
        </Column>
      </Table>
      <br />
      <br />
    </div>
  );
};

export default ClubDetail;
