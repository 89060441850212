import "./headerh2h.less";
import { useQuery } from "@tanstack/react-query";
import { getPlayersByString } from "../../../../services/h2h";
import { getTeamsByString } from "../../../../services/h2h";
import { CACHE_TIME_1_HOUR } from "../../../../utils/constants";
import { useEffect, useState } from "react";
import { HeaderH2HProps } from "../types";
import AutoComplete from "../../../../components/Autocomplete/Autocomplete";
import { OptionType } from "../../../../components/Autocomplete/types";
import { Team } from "../../../../interfaces/team";
import { Player } from "../../../../interfaces/player";
import { Loader } from "rsuite";
import { PlayerH2H, TeamH2H } from "../../../../interfaces/h2h";

// const brokenImage =
//   "https://static.vecteezy.com/system/resources/previews/005/337/799/non_2x/icon-image-not-found-free-vector.jpg";

export const HeaderH2H = ({
  fetching,
  onModelsSelected,
  modelOne,
  modelTwo,
  type,
}: HeaderH2HProps) => {
  const [model1Search, setModel1Search] = useState("");
  const [model2Search, setModel2Search] = useState("");
  const [model1Selected, setModel1Selected] = useState<Player | Team | null>(null);
  const [model2Selected, setModel2Selected] = useState<Player | Team | null>(null);

  const handleSelectModel1 = (model: OptionType | null) => {
    setModel1Selected(model);
    if (model2Selected?.id && model) {
      onModelsSelected(model.id, model2Selected.id);
    }
  };

  const handleSelectModel2 = (model: OptionType | null) => {
    setModel2Selected(model);
    if (model1Selected?.id && model) {
      onModelsSelected(model1Selected.id, model.id);
    }
  };

  const handleChangeModel1 = (value: string) => setModel1Search(value);
  const handleChangeModel2 = (value: string) => setModel2Search(value);

  const getPicture = (model: HeaderH2HProps["modelOne"]) => {
    if (type === "player") return (model as PlayerH2H)?.player?.picture;
    if (type === "team") return (model as TeamH2H)?.team?.club?.logo;
  };

  const getPicture2 = (model: Player | Team) => {
    if (type === "player") return (model as Player)?.picture;
    if (type === "team") return (model as Team)?.club?.logo;
  };

  const getWins = (model: HeaderH2HProps["modelOne"]) => {
    if (type === "player") return (model as PlayerH2H)?.matches_won;
    if (type === "team") return (model as TeamH2H)?.showdowns_won;
  };

  const getId = (model: HeaderH2HProps["modelOne"]) => {
    if (type === "player") return (model as PlayerH2H)?.player?.id || 0;
    if (type === "team") return (model as TeamH2H)?.team?.id || 0;
    return 0;
  };
  const getName = (model: HeaderH2HProps["modelOne"]) => {
    if (type === "player") return (model as PlayerH2H)?.player?.name || "";
    if (type === "team") return (model as TeamH2H)?.team?.name || "";
    return "";
  };

  const models1Query = useQuery<Team[] | Player[]>(
    [type === "player" ? "searchPlayer" : "searchTeam", model1Search],
    () => (type === "player" ? getPlayersByString(model1Search) : getTeamsByString(model1Search)),
    {
      staleTime: CACHE_TIME_1_HOUR,
      enabled: !!model1Search,
    }
  );

  const models2Query = useQuery<Team[] | Player[]>(
    [type === "player" ? "searchPlayer" : "searchTeam", model2Search],
    () => (type === "player" ? getPlayersByString(model2Search) : getTeamsByString(model2Search)),
    {
      staleTime: CACHE_TIME_1_HOUR,
      enabled: !!model2Search,
    }
  );

  const renderListItem = () => {
    if (type === "player") {
      return (option: Player) => (
        <div className="container-list-item">
          <div className="container-list-item-image">
            {option.picture && <img src={option.picture} alt="" />}
          </div>
          <div className="data-list-item">
            <h5>{option.name}</h5>
            <h6>{option.club?.name}</h6>
          </div>
        </div>
      );
    } else {
      return (option: Team) => (
        <div className="container-list-item">
          <div className="container-list-item-image">
            {option.club?.logo && <img src={option.club?.logo} alt="" />}
          </div>
          <div className="data-list-item">
            <h5>{option.name}</h5>
            <h6>{option.club?.name}</h6>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    setModel1Search("");
    setModel2Search("");
    setModel1Selected(null);
    setModel2Selected(null);
  }, [type]);

  useEffect(() => {
    if (modelOne && !model1Selected)
      setModel1Selected({ id: getId(modelOne), name: getName(modelOne) });
    if (modelTwo && !model2Selected)
      setModel2Selected({ id: getId(modelTwo), name: getName(modelTwo) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelOne, modelTwo, model1Selected, model2Selected]);

  const autocompletePlaceholder = type === "player" ? "Buscá el jugador" : "Buscá el equipo";

  return (
    <div className="header-container">
      <div className="title">
        <h1>HEAD TO HEAD</h1>
        <h3>{type === "player" ? "Jugadores" : "Equipos"}</h3>
      </div>
      <div className="match-stats">
        <div className="image-input">
          <div className="image-container">
            {fetching ? (
              <div>
                <Loader inverse speed="slow" center size="md" />
              </div>
            ) : getPicture(modelOne) ? (
              <img src={getPicture(modelOne)} alt="logo" />
            ) : model1Selected && getPicture2(model1Selected) ? (
              <img src={getPicture2(model1Selected)} alt="logo" />
            ) : null}
          </div>
          <AutoComplete
            placeholder={autocompletePlaceholder}
            options={(models1Query?.data || []) as OptionType[]}
            searching={models1Query?.isFetching}
            onSelect={handleSelectModel1}
            onSearch={handleChangeModel1}
            value={
              model1Selected ? { id: model1Selected.id || 0, name: model1Selected.name } : null
            }
            renderListItem={renderListItem()}
            // renderSelectedItem={type === 'team' ? renderPlayerSelectedItem : renderPlayerSelectedItem}
          />
        </div>
        <div className="win-stats">
          <div className="win-one">
            <p>{getWins(modelOne)}</p>
          </div>
          <div className="chart">
            <h2>vs</h2>
            <h2>{type === "team" ? "Encuentros" : "Partidos"}</h2>
          </div>
          <div className="win-two">
            <p>{getWins(modelTwo)}</p>
          </div>
        </div>
        <div className="image-input">
          <div className="image-container">
            {fetching ? (
              <div>
                <Loader inverse speed="slow" center size="md" />
              </div>
            ) : getPicture(modelTwo) ? (
              <img src={getPicture(modelTwo)} alt="logo" />
            ) : model2Selected && getPicture2(model2Selected) ? (
              <img src={getPicture2(model2Selected)} alt="logo" />
            ) : null}
          </div>
          <AutoComplete
            placeholder={autocompletePlaceholder}
            options={(models2Query?.data || []) as OptionType[]}
            searching={models2Query?.isFetching}
            onSelect={handleSelectModel2}
            onSearch={handleChangeModel2}
            value={
              model2Selected ? { id: model2Selected.id || 0, name: model2Selected.name } : null
            }
            renderListItem={renderListItem()}
          />
        </div>
      </div>
    </div>
  );
};
