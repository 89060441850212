import "./rules.less";

const Rules = () => {
  return (
    <div className="page-container rules-container">
      <h1>Reglamento Interclubes de Squash 2024</h1>
      <h4 className="rules-title">Jugadores</h4>
      <ul>
        <li>
          Sólo podrán participar aquellos jugadores que sean socios de la institución que
          representen.
        </li>
        <li>
          Los jugadores socios de dos o más clubes, podrán estar en las listas de solamente uno.
        </li>
      </ul>
      <h4 className="rules-title">Lista de buena fe</h4>
      <ul>
        <li>
          Deberá incluir como mínimo a 8 jugadores. Los mismos deberán estar ordenados, de buena fe,
          de acuerdo al nivel de juego, en ranking ascendente, donde el mejor jugador será el N° 1
          de la lista y así sucesivamente.
        </li>
        <li>
          Deberá incluir a un Capitán y un sub-Capitán o 2do. responsable, con sus números de
          teléfono.
        </li>
        <li>
          Se debe enviar al chat Whatsapp de Capitanes de la categoría en formato excel en la
          planilla modelo, con apellido y nombre (sin seudónimos).
        </li>
        <li>
          En las LBF de los equipos, los primeros 5 jugadores de la misma pertenecen sólo a ese
          equipo, del 6 en adelante se los considera jugadores comunes. A la hora de armar los
          equipos, el capitán deberá agotar primero todos los jugadores de su lista antes de
          recurrir a los comunes con el otro equipo del mismo club. De suceder esta situación, el
          capitán debe informar al capitán del equipo rival. El puesto que ocupen en el equipo los
          jugadores comunes convocados se determinará de acuerdo a la LBF general del club.
        </li>
        <li>
          La LBF debe ser enviada al chat de los Capitanes de cada Categoría con una anticipación de
          (7) siete días a la fecha establecida como la de inicio del torneo.
        </li>
        <li>La LBF deberá estar exhibida en las canchas de los clubes participantes.</li>
        <li>
          Todos los jugadores que integran la LBF de una categoría inferior pueden jugar en la
          categoría superior del mismo club.
        </li>
      </ul>
      <h4 className="rules-title">Equipos</h4>
      <ul>
        <li>Los equipos estarán formados por 5 jugadores titulares numerados de 1 a 5.</li>
        <li>
          Bajo ningún concepto podrán formar parte del equipo jugadores que no estén en la LBF
          (salvo que estén en una LBF de una categoría inferior).
        </li>
      </ul>
      <h4 className="rules-title">Capitán y sub Capitán</h4>
      <ul>
        <li>
          Tienen la responsabilidad de la organización del evento del día. Se ocuparán de la
          disponibilidad de canchas, vestuarios, toallas, tercer tiempo, formación del equipo y
          deberán interceder de producirse cualquier inconveniente durante el transcurso de los
          partidos.
        </li>
        <li>
          En el caso de producirse algún conflicto con el equipo y/o jugadores de otro club, deberán
          procurar solucionarlo junto al capitán y/o sub capitán del otro equipo.
        </li>
        <li>
          De no llegar a un acuerdo, deberán enviar un chat únicamente a algún integrante de la
          Coordinación. (Ino Olivera, Nicolás Strada, Fernando Cano, Ignacio Díaz y Tony Sidero)
          explicando el caso.
        </li>
        <li>
          Conformación de equipos de cada fecha: Se deberá confirmar el equipo en PRÓXIMA FECHA en
          la web antes de las 18 hs del jueves
        </li>
        <li>
          Resultado: Una vez concluidos los 5 partidos de la fecha, se deberán cargar en la web como
          RESULTADO, acordando los parciales de cada partido. Es importante que esto se haga de la
          manera más exacta ya que son valores que determinan la ubicación en la tabla.
        </li>
        <li>
          Por favor, en caso de que el capitán no asista a esa fecha, asignar a algún jugador que se
          encargue de cargar el resultado de la fecha. El capitán local carga el resultado y el
          capitán visitante confirma que los resultados sean los correctos. En caso de que el local
          no lo cargue, se le pide al visitante lo haga.
        </li>
      </ul>
      <h4 className="rules-title">Comité</h4>
      <ul>
        <li>
          Funciones
          <ul>
            <li>Resolver los conflictos que pudieran presentarse.</li>
            <li>Interpretar y resolver cuestiones no contempladas en el reglamento.</li>
            <li>Agregar jugadores a la LBF vencido el plazo estipulado.</li>
          </ul>
        </li>
        <li>Las resoluciones se tomarán por simple mayoría.</li>
        <li>
          En el caso que el conflicto involucre al club al cual pertenece alguno de los miembros del
          Comité, éste deberá excusarse y ser reemplazado por el 1er miembro suplente. En caso que
          el conflicto involucre a los equipos de dos miembros del Comité, éstos serán reemplazados
          por el 1ro y 2do suplentes.
        </li>
        <li>
          Los miembros del comité deben estar presentes en la reunión de principios de año, si no
          pierden su lugar. Cada año se rotará al menos dos miembros del comité.
        </li>
      </ul>
      <h4 className="rules-title">Torneo</h4>
      <ul>
        <li>
          La modalidad del torneo será:
          <ul>
            <li>APERTURA 2024.</li>
            <p>
              <b>1ra, 2da y 3ra</b> (8 equipos), todos contra todos ida y vuelta. El ganador del
              torneo será el Campeón del Apertura. El 8vo de 1ra desciende a 2da Clausura, el
              campeón de 2da asciende a 1ra Clausura. El 8vo de 2da desciende a 3ra Clausura, el
              campeón de 3ra asciende a 2da Clausura.Se jugará una Reubicación entre el 7mo de cat
              superior y el 2do de categoría inferior, con localía del de la categoría superior, el
              jueves 3 de agosto. El ganador de ese partido jugará en 1ra. Del mismo modo será en
              2da y 3ra.
            </p>
            <p>
              <b>4ta</b> (14 equipos) todos contra todos ida y luego Final entre 1 vs 2. La localía
              del 1 (uno). El ganador será el Campeón del Apertura y ascenderá a 2da en el Clausura.
              El perdedor de la final jugará la Reubicación el 3 de agosto.
            </p>
            <p>Para la Reubicación rigen las reglamentaciones del Torneo de Todos Contra Todos.</p>
            <li>CLAUSURA 2024.</li>
            <p>
              <b>1ra, 2da y 3ra</b> (8 equipos), todos contra todos ida y vuelta. La 3ra estará
              conformada con los 8 primeros equipos del Apertura, menos el campeón, incluyendo al
              perdedor de la Reubicación de 2da. Los ganadores de los 3 torneos serán los Campeones
              del Clausura. Los Campeones de 2da y 3ra ascenderán a la categoría superior en el año
              2025. El último de cada categoría descenderá a la inferior en el año 2024. Habrá
              Reubicación entre 7mo de cat. sup. y 2do de inf. el jueves 28 de noviembre, siendo
              local el equipo de la categoría superior.
            </p>
            <p>
              <b>4ta</b> 12 equipos, todos contra todos ida, a partir del jueves 14 de octubre COPA
              de Oro entre los 6 primeros y Copa de Plata del 7 al 12, ambos torneos todos contra
              todos ida. El ganador de la Copa de Oro será el Campeón del Clausura y ascenderá a 3ra
              en 2025. El Subcampeón jugará la Reubicación de 3ra el 28 de noviembre.
            </p>
          </ul>
        </li>
        <li>
          Se juega los días jueves solamente (salvo que la imposibilidad de confeccionar el fixture
          así lo requiera, la final de fin de año que puede ser viernes o razones de causa mayor
          autorizadas por la Coordinación)
        </li>
        <li>Los equipos del mismo club deben enfrentarse en las primeras fechas de cada torneo.</li>
        <li>
          En cada fecha se disputarán 5 partidos entre los jugadores titulares designados para ese
          día, enfrentándose los que ocupen la misma posición, es decir el 1 con el 1 y así
          sucesivamente.
        </li>
        <li>
          Los partidos se jugarán al mejor de 5 games y a 11 puntos cada game. En caso de empatar en
          el game en 10 puntos, se jugará un tie break y resultará ganador el jugador que obtenga
          una diferencia de 2 puntos a su favor.
        </li>
        <li>La fecha la gana el equipo que triunfa en la mayor cantidad de partidos.</li>
        <li>
          Cada partido ganado suma 1 punto y el equipo que gana la fecha suma 2 puntos adicionales.
        </li>
        <li>
          Para determinar la ubicación de los equipos en la tabla de posiciones se sumarán los
          puntos obtenidos en cada fecha.
        </li>
        <li>
          En caso de empate en puntos, se define por (1) Cantidad de fechas ganadas; (2) Cantidad de
          partidos ganados; (3) cantidad de games ganados; (4)menor cantidad de games perdidos
        </li>
        <li>
          Para la Final Anual de Segunda y Tercera Categoría sólo podrán integrar los equipos como
          1, 2 y 3 de la LBF aquellos jugadores hayan participado en la etapa de clasificación en un
          mínimo 40% de la cantidad de partidos disputados por el equipo sin contabilizar WOs. Con
          decimales, se redondea para arriba. No habrá restricciones con respecto a la cantidad de
          partidos disputados en categoría superior.
        </li>
        <li>
          En Primera esta regla sólo aplica para los puestos 1 y 2 del día de la Final Anual, los
          jugadores que jueguen en esas dos posiciones ese día deberán haber jugado un mínimo de 40%
          (se redondea para arriba) de los partidos del año.
        </li>

        <li>
          La sede de la final del año será Belgrano Athletic Club, el jueves 5 de diciembre. Cada
          equipo deberá pagar al menos 5 cubiertos, participe o no.
        </li>
      </ul>
      <h4 className="rules-title">Infracciones, organización y recomendaciones</h4>
      <ul>
        <li>
          En el caso de que no se respete el orden de la LBF el equipo infractor perderá por WO
          (0-7.)
        </li>
        <li>
          Para todos los casos de W.O. El equipo infractor no se adjudicará puntos, mientras que el
          perjudicado recibirá 7 puntos.
        </li>
        <li>
          Cualquier equipo que presente menos de 5 jugadores perderá indefectiblemente por W.O. En
          el caso de reincidir en un W.O., perderán 3 puntos TODOS LOS EQUIPOS DE ESE CLUB y
          adjudicará 7 al rival, si esto sucede por 3ª vez, perderá nuevamente 3 puntos TODOS LOS
          EQUIPOS DE ESE CLUB y adjudicará 7 al otro y se invitará a ese CLUB que a partir del año
          siguiente elimine su equipo de la categoría más baja.
        </li>
        <li>
          Si un club decide eliminar un equipo del campeonato debe eliminar aquel equipo que se
          encuentre en la categoría más baja, y de incluir uno nuevo también será por ella.
        </li>
        <li>
          El club que presente un equipo con uno o más jugadores que no hayan sido incluidos en la
          LBF, perderá la fecha por W.O.
        </li>
        <li>
          Se sugiere que el día anterior a cada fecha, los capitanes de ambos equipos deben informar
          por chat la conformación de los equipos y horarios de los partidos.
        </li>
        <li>Se establece las. 20hs como hora límite para empezar los partidos de la fecha.</li>
        <li>
          Si a las 20hs. Si no ha llegado al club ningún jugador de uno de los equipos, se
          considerará W.O. y se informará a la Coordinación.
        </li>
        <li>
          Se recomienda comenzar el primer partido a las 19 hs. para que el tercer tiempo sea más
          prolongado.
        </li>
        <li>
          El referí será opcional, intentando que cada partido tenga uno para decidir los pedidos de
          LET. En el caso de ser solicitado, ya sea por uno de los jugadores o capitanes, se
          designará como tal, previo acuerdo entre los capitanes, a uno de los presentes.
        </li>
        <li>
          No existe bajo ningún aspecto la posibilidad de posponer y/o suspender la fecha, salvo en
          casos de fuerza mayor que deben ser resueltos por el Comité. Si por algún inconveniente
          hay imposibilidad de jugar en la cancha del club local, se puede solicitar el cambio de
          localía. En caso de suspenderse la fecha por causas imputables al club local, se
          considerará como un W.O. para el mismo.
        </li>
      </ul>
      <h4 className="rules-title">Posibilidad de jugar en Categorías superiores</h4>
      <ul>
        <li>
          No podrán participar los jugadores de Categorías superiores que estén en los puestos 1 al
          5 de la LBF de esa categoría.
        </li>
        <li>
          En las categorías de Primera y Segunda, sólo podrán participar los jugadores suplentes que
          estén en los 15 lugares de la Lista de Buena Fe siguientes al último jugador del equipo de
          esa categoría.
        </li>
        <li>
          No está permitido participar en cotejos puntuables de distintos equipos y/o categorías en
          la misma fecha.
        </li>
      </ul>
      <h4 className="rules-title">Tercer tiempo</h4>
      <ul>
        <li>
          Es obligación del club local agasajar a los jugadores visitantes con un 3er. tiempo.
        </li>
        <li>
          El 3er. tiempo deberá consistir de una cena que incluya un plato principal, postre, café,
          gaseosa y cerveza. Vino, tragos y picada son opcionales.
        </li>
        <li>
          Es obligatoria la presencia de todos los jugadores en el 3er. Tiempo. Se recomienda a los
          Capitanes no incluir como titulares en sus equipos a aquellos jugadores que no participan
          del tercer tiempo.
        </li>
        <li>
          Para el 3er. tiempo de la final se recomienda la participación de todos los jugadores del
          torneo. El costo del mismo será solventado por todos los clubes considerándose la
          asistencia de 5 representantes por cada institución. Cada equipo deberá pagar al menos 5
          (cinco) cubiertos, asista o no al evento.
        </li>
      </ul>
      <h4 className="rules-title">Premios</h4>
      <ul>
        <li>
          Se entregará copa para el Campeón y Subcampeón del año de cada categoría y a los campeones
          de la Copa de Plata de 4ta. También se premiará el Fair Play y el Mejor 3er. Tiempo, que
          saldrá de la votación por chat de Capitanes (Fernando Cano será el responsable de ese
          conteo).
        </li>
      </ul>
      <h4 className="rules-title">Ascensos, descensos y otros</h4>
      <ul>
        <li>
          Hay un ascenso y un descenso por categoría por cada campeonato. También se disputará una
          Reubicación, entre el 7mo de cat. sup y el 2do de cat. inf. con localía del de categoría
          superior.
        </li>
        <li>
          A fin de año se juega la final para determinar el Campeón anual, entre los campeones del
          Apertura y el Clausura.
        </li>
        <li>
          El orden de los partidos para la final de fin de año es: (1ro) el 5, (2do) el 4, si van
          1-1 juega el 3 y sino el mejor partido disponible, a partir de allí, el mejor partido
          disponible siempre.
        </li>
        <li>
          Si en Primera es el mismo, se disputará un encuentro entre el Campeón y los mejores
          jugadores del resto de los equipos de 1ra.
        </li>
        <li>
          Si un equipo gana 3ra y luego 2da sólo disputa la final de 2da y el otro campeón de 3ra es
          el campeón del año.
        </li>
        <li>
          El campeón del año por cada categoría que figura en las Copas será quien gane la final en
          la sede que se determine.
        </li>
      </ul>
    </div>
  );
};

export default Rules;
