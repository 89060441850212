import dayjs from "dayjs";
import { LastResult, LastResultTeam } from "../interfaces/h2h";

const getWinner = (result: any, field: "games" | "matchs") => {
  const homeKey = `${field}_win_home`;
  const awayKey = `${field}_win_away`;
  const isHomeWinner = result[homeKey] > result[awayKey];
  const winner = result[homeKey] > result[awayKey] ? result[homeKey] : result[awayKey];
  const looser = result[homeKey] > result[awayKey] ? result[awayKey] : result[homeKey];
  const localia = result[homeKey] > result[awayKey] ? "Local" : "Visitante";

  return {
    isHomeWinner,
    winner,
    looser,
    localia,
  };
};

export const mapLastResultsPlayer = (result: any) => {
  const { isHomeWinner, winner, looser, localia } = getWinner(result, "games");

  return {
    date: dayjs(result.date).format("D MMM, YYYY"),
    logo: isHomeWinner ? result.home_player.picture : result.away_player.picture,
    name: isHomeWinner ? result.home_player.name : result.away_player.name,
    tournament: result.tournament_name,
    result: `${winner}-${looser}`,
    localia: localia,
  };
};

export const mapLastResultsTeam = (result: any) => {
  const { isHomeWinner, winner, looser, localia } = getWinner(result, "matchs");

  return {
    date: dayjs(result.date).format("D MMM, YYYY"),
    logo: isHomeWinner ? result?.home?.club?.logo : result?.away?.club?.logo,
    name: isHomeWinner ? result.home.name : result.away.name,
    tournament: result.tournament_name,
    result: `${winner}-${looser}`,
    localia: localia,
  };
};

export const sortResultsByDate = (a: LastResult | LastResultTeam, b: LastResult | LastResultTeam) =>
  dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1;
