import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Navbar as RSNavbar, Nav, Drawer, Sidenav, IconButton } from "rsuite";
import MenuIcon from "@rsuite/icons/Menu";
import "./navbar.less";
import SquashLogo from "../../assets/dark_logo.png";
import { CONTACT_FORM_ID } from "../../utils/constants";
import { scrollTo } from "../../utils/scrollTo";

const Navbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleContact = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
    setTimeout(() => {
      if (pathname !== "/") navigate("/?to=contacto");
      else scrollTo(CONTACT_FORM_ID);
    }, 400);
  };

  const renderOptions = () => (
    <>
      <Nav.Item onClick={() => setOpen(false)}>
        <Link to="/torneos">Torneos</Link>
      </Nav.Item>
      <Nav.Item onClick={() => setOpen(false)}>
        <Link to="/clubes">Clubes</Link>
      </Nav.Item>

      <Nav.Menu title="H2H" menuStyle={{ minWidth: 100 }}>
        <Nav.Item onClick={() => setOpen(false)}>
          <Link to="/h2h/equipos">Equipos</Link>
        </Nav.Item>
        <Nav.Item onClick={() => setOpen(false)}>
          <Link to="/h2h/jugadores">Jugadores</Link>
        </Nav.Item>
      </Nav.Menu>

      <Nav.Item onClick={() => setOpen(false)}>
        <Link to="/reglamento">Reglamento</Link>
      </Nav.Item>
      <Nav.Item onClick={handleContact}>
        <Link to="#">Contacto</Link>
      </Nav.Item>
    </>
  );

  return (
    <>
      <div className="navbar-container">
        <RSNavbar>
          <IconButton className="menu-icon" icon={<MenuIcon />} onClick={() => setOpen(true)} />
          <RSNavbar.Brand className="logo-container" href="/">
            <Link to="/">
              <img className="logo-image" src={SquashLogo} alt="logo" />
            </Link>
          </RSNavbar.Brand>
          <Nav pullRight className="options-container">
            {renderOptions()}
          </Nav>
        </RSNavbar>
      </div>
      <Drawer open={open} onClose={() => setOpen(false)} size="sm" placement="left">
        <Drawer.Body className="drawer-content">
          <Sidenav>
            <Sidenav.Body>
              <div className="drawer-image-container">
                <img className="drawer-image" src={SquashLogo} alt="logo" />
              </div>
              <Nav>{renderOptions()}</Nav>
            </Sidenav.Body>
          </Sidenav>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default Navbar;
