import { Pie } from "react-chartjs-2";
import { PlayerStats } from "../../../../interfaces/playerStats";
import { getStats } from "../../../../utils/stats";
import { StatsProps } from "./types";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./stats.less";
import { Col, Grid, Row, Placeholder } from "rsuite";

ChartJS.register(ArcElement, Tooltip, Legend);

const chartConfig = {
  responsive: true,
  maintainAspectRatio: false,
};

const COLORS = {
  won: "#00bcd4",
  lost: "#A7261C",
};

export const Stats = ({ playerStats, isFetching }: StatsProps) => {
  const renderItemStats = (label: string, value: string | number, color?: string) => {
    return (
      <li className="li-information-stats">
        <div className="stats-information-bullet" style={{ backgroundColor: color }}></div>
        <span className="info-information legend">{label}</span>
        <span className="value-information">{value}</span>
      </li>
    );
  };

  const renderStats = (player: PlayerStats | undefined, field: "games" | "matches") => {
    if (!player) return null;

    const stats = getStats(player, field);
    const label = field === "games" ? "Games" : "Partidos";
    const labels = [`${label} Perdidos`, `${label} Ganados`];
    const data = {
      datasets: [
        {
          labels: labels,
          data: [stats.lost, stats.won],
          borderColor: "#33373d",
          backgroundColor: [COLORS.lost, COLORS.won],
        },
      ],
    };
    return (
      <div className="pie">
        <div className="pie-chart-container">
          <Pie className="pie-chart" options={chartConfig} data={data} />
        </div>
        <div className="stats-information">
          <ul>
            {renderItemStats(`${label} ganados: `, stats.won ?? "-", COLORS.won)}
            {renderItemStats(`${label} perdidos: `, stats.lost ?? "-", COLORS.lost)}
            {renderItemStats(`${label} jugados: `, stats.played ?? "-")}
          </ul>
        </div>
      </div>
    );
  };

  if (isFetching) {
    return (
      <Grid style={{ width: "100% ", marginTop: 32 }}>
        <Row gutter={24}>
          <Col xs={24} md={12} style={{ marginBottom: 16 }}>
            <Placeholder.Graph active height={330} style={{ borderRadius: 16 }} />
          </Col>
          <Col xs={24} md={12} style={{ marginBottom: 16 }}>
            <Placeholder.Graph active height={330} style={{ borderRadius: 16 }} />
          </Col>
        </Row>
      </Grid>
    );
  }

  return (
    <div className="stats">
      <div className="card-stats">
        <div className="title-stats">
          <h2>Partidos</h2>
        </div>
        {renderStats(playerStats, "matches")}
      </div>
      <div>
        <div className="title-stats">
          <h2>Games</h2>
        </div>
        {renderStats(playerStats, "games")}
      </div>
    </div>
  );
};
