import { PlayerStats, Stats } from "../interfaces/playerStats";
import { TeamsStats } from "../interfaces/teamStats";

export const getStats = (player: PlayerStats, field: "games" | "matches"): Stats => {
  if (field === "games") {
    return {
      won: player.games.games_won,
      lost: player.games.games_lost,
      played: player.games.games_played,
      percentage_lost: player.games.games_percentage_lost,
      percentage_won: player.games.games_percentage_won,
    };
  }

  return {
    won: player.matches.matches_won,
    lost: player.matches.matches_lost,
    played: player.matches.matches_played,
    percentage_lost: player.matches.matches_percentage_lost,
    percentage_won: player.matches.matches_percentage_won,
  };
};

export const getTeamStats = (team: TeamsStats, field: "showdowns" | "matches"): Stats => {
  if (field === "showdowns") {
    return {
      won: team.showdowns.showdowns_won,
      lost: team.showdowns.showdowns_lost,
      played: team.showdowns.showdowns_played,
      percentage_lost: team.showdowns.showdowns_percentage_lost,
      percentage_won: team.showdowns.showdowns_percentage_won,
    };
  }

  return {
    won: team.matches.matches_won,
    lost: team.matches.matches_lost,
    played: team.matches.matches_played,
    percentage_lost: team.matches.matches_percentage_lost,
    percentage_won: team.matches.matches_percentage_won,
  };
};
