import { useParams } from "react-router-dom";
import "./teamInfo.less";
import { useQuery } from "@tanstack/react-query";
import { CACHE_TIME_10_MINUTES } from "../../utils/constants";
import { LastResults } from "./components/LastResults/LastResults";
import { Stats } from "./components/Stats/Stats";
import { Col, Grid, Row, Placeholder } from "rsuite";
import { useEffect } from "react";
import { getTeamStats } from "../../services/team";

const TeamInfo = () => {
  const { id } = useParams();

  const query = useQuery(["team", Number(id)], () => getTeamStats(Number(id)), {
    enabled: !!id,
    staleTime: CACHE_TIME_10_MINUTES,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <div className="page-container">
      {query.isFetching ? (
        <Grid style={{ width: "100% ", marginTop: 32 }}>
          <Row gutter={24}>
           <Col xs={24} md={8} style={{ marginBottom: 16 }}>
              <Placeholder.Graph active height={32} style={{ borderRadius: 8 }} />
            </Col>
          </Row>
        </Grid>
      ) : (
        <div className="team-name">
          <h1>{query.data?.team?.name}</h1>
        </div>
      )}
      <div>
        <Stats isFetching={query.isFetching} teamStats={query.data} />
      </div>
      <LastResults
        teamId={query.data?.team?.id}
        isFetching={query?.isFetching}
        lastResults={query.data?.last_results}
      />
    </div>
  );
};

export default TeamInfo;
