export interface CupFormatLibray {
  participant: CupParticipant[];
  stage: CupStage[];
  group: CupGroup[];
  round: CupRound[];
  match: CupMatch[];
}

export interface CupParticipant {
  id: number;
  tournament_id: number;
  name: string;
}

export interface CupStage {
  id: number;
  tournament_id: number;
  name: string;
  type: "single_elimination" | "double_elimination";
  number: number;
  settings: CupStageSettings;
}

export interface CupStageSettings {
  size: number;
  seedOrdering: string[];
  grandFinal: "single";
  matchesChildCount: number;
}

export interface CupGroup {
  id: number;
  stage_id: number;
  number: number;
}

export interface CupRound {
  id: number;
  stage_id: number;
  group_id: number;
  number: number;
}

export interface CupMatch {
  id: number;
  number: number;
  stage_id: number;
  group_id: number;
  round_id: number;
  child_count: number;
  status: MatchStatus;
  opponent1: CupMatchOpponent;
  opponent2: CupMatchOpponent;
}

export enum MatchStatus {
  pending = 3,
  completed = 4,
}

export interface CupMatchOpponent {
  id: number;
  score: number;
  result: "win" | "loss";
}
