import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ActiveTournaments from "./components/ActiveTournaments/ActiveTournaments";
import Stats from "./components/Stats/Stats";
import Contact from "../../components/Contact/Contact";
import Front from "./components/Front/Front";
import { scrollTo } from "../../utils/scrollTo";
import { CONTACT_FORM_ID } from "../../utils/constants";

const Home = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const to = searchParams.get("to");
    if (to && to === "contacto") scrollTo(CONTACT_FORM_ID);
  }, [searchParams]);

  return (
    <>
      <Front />
      <ActiveTournaments />
      <Stats />
      <Contact />
    </>
  );
};

export default Home;
