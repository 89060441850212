import { Modal, Grid, Row, Col } from "rsuite";
import { ShowdownProps } from "./types";
import "./showdown.less";
import { Match } from "../../../../interfaces/showdown";

const Showdown = ({ open, showdown, onClose }: ShowdownProps) => {
  const renderGame = (id: string, points: number | string) => {
    return <input className="game" key={id} type="text" maxLength={2} value={points} readOnly />;
  };

  const renderGames = (match: Match, team: "home" | "away", showGamesDetails: boolean) => {
    if (!showdown?.matchs_win_away && !showdown?.matchs_win_home) {
      return <p className="game-empty">-</p>;
    }

    if (showGamesDetails) {
      return (match.games || []).map((game) => {
        const id = `match-${match.id}-game-${game.id}`;
        const points = team === "home" ? game.home_points : game.away_points;
        return renderGame(id, points || 0);
      });
    }

    const points = team === "home" ? match.games_win_home : match.games_win_away;
    return renderGame(`match-${match.id}`, points || 0);
  };

  const renderMatch = (match: Match, index: number) => {
    const showGamesDetails =
      (match.games &&
        match.games.length > 0 &&
        match.games.some((game) => game.away_points !== null && game.home_points !== null)) ||
      false;
    return (
      <Col xs={24} md={12} xl={8} className="match-column">
        <div className="match-header">
          <h5>{`Partido ${index + 1}`}</h5>
          {match.home_player?.id && match.away_player?.id && (
            <a
              className="match-h2h"
              href={`/h2h/jugadores?home=${match.home_player.id}&away=${match.away_player.id}`}
              target="_blank"
              rel="noreferrer"
            >
              Ver H2H
            </a>
          )}
        </div>
        <div className="match-container">
          <div className="player-container">
            <div className="player-content">
              <div className="player-image-container">
                <img
                  src={match.home_player?.picture || showdown?.home?.club?.logo}
                  className="player-image"
                  alt={match.home_player?.name}
                />
              </div>
              <p className="player-name">{match.home_player?.name || ""}</p>
            </div>
            <div className={`games-container ${showGamesDetails ? "games-detail" : ""}`}>
              {renderGames(match, "home", showGamesDetails)}
            </div>
          </div>
          <div className="match-divider"></div>
          <div className="player-container">
            <div className="player-content">
              <div className="player-image-container">
                <img
                  src={match.away_player?.picture || showdown?.away?.club?.logo}
                  className="player-image"
                  alt={match.away_player?.name}
                />
              </div>
              <p className="player-name">{match.away_player?.name || ""}</p>
            </div>
            <div className={`games-container ${showGamesDetails ? "games-detail" : ""}`}>
              {renderGames(match, "away", showGamesDetails)}
            </div>
          </div>
        </div>
      </Col>
    );
  };

  return (
    <Modal open={open} onClose={onClose} backdrop size="full">
      <Modal.Header>
        <Modal.Title className="showdown-title">
          {showdown ? `${showdown?.home?.name || ""} vs ${showdown?.away?.name || ""}` : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid fluid>
          <Row>{showdown?.matchs?.map(renderMatch)}</Row>
        </Grid>
      </Modal.Body>
    </Modal>
  );
};

export default Showdown;
