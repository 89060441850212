import "./h2h.less";
import { useQuery } from "@tanstack/react-query";
import { CACHE_TIME_1_HOUR } from "../../utils/constants";
import { Table } from "rsuite";
import { Player } from "../../interfaces/player";
import { getPlayersH2H } from "../../services/h2h";
import { getTeamsH2H } from "../../services/h2h";
import { useState, useEffect } from "react";
import { HeaderH2H } from "./components/HeaderH2H/HeaderH2H";
import { calculateAge } from "../../utils/calculateAge";

import { PlayerH2H, TeamH2H } from "../../interfaces/h2h";
import { H2HProps } from "./types";
import { mapLastResultsPlayer, mapLastResultsTeam, sortResultsByDate } from "../../utils/lastResults";
import { Loader } from "rsuite";
import { useSearchParams } from "react-router-dom";

const { Column, HeaderCell, Cell } = Table;

const H2H = ({ type }: H2HProps) => {
  const [modelOneId, setModelOneId] = useState(0);
  const [modelTwoId, setModelTwoId] = useState(0);

  const [searchParams] = useSearchParams();

  const modelOne = searchParams.get("home");
  const modelTwo = searchParams.get("away");

  const playerQuery = useQuery(
    ["playersH2H", modelOneId, modelTwoId],
    () => getPlayersH2H(modelOneId, modelTwoId),
    {
      staleTime: CACHE_TIME_1_HOUR,
      enabled: !!modelOneId && !!modelTwoId && type === "player",
      select: (data) => {
        return {
          player_one: data.player_one,
          player_two: data.player_two,
          last_results: data.last_results.sort(sortResultsByDate).map(mapLastResultsPlayer),
        };
      },
    }
  );
  const teamQuery = useQuery(
    ["teamsH2H", modelOneId, modelTwoId],
    () => getTeamsH2H(modelOneId, modelTwoId),
    {
      staleTime: CACHE_TIME_1_HOUR,
      enabled: !!modelOneId && !!modelTwoId && type === "team",
      select: (data) => {
        return {
          team_one: data.team_one,
          team_two: data.team_two,
          last_results: data.last_results.sort(sortResultsByDate).map(mapLastResultsTeam),
        };
      },
    }
  );

  const handleModelsSelect = (model1: number, model2: number) => {
    setModelOneId(model1);
    setModelTwoId(model2);
  };

  const ImageCell = ({ rowData, dataKey, ...props }: any) => (
    <Cell {...props} style={{ padding: 0 }}>
      <div className="clubs-logo-container">
        {rowData.logo && <img src={rowData.logo} className="clubs-logo" alt={rowData.name} />}
      </div>
    </Cell>
  );

  const renderItemPlayerData = (label: string, value: string | number) => {
    return (
      <li className="li">
        <span className="info">{label}</span>
        <span className="value">{value}</span>
      </li>
    );
  };
  const renderItemPlayerDataPhone = (
    value1: string | number,
    label: string,
    value2: string | number
  ) => {
    return (
      <li className="li">
        <span className="value">{value1}</span>
        <span className="info">{label}</span>
        <span className="value">{value2}</span>
      </li>
    );
  };

  const renderPlayerData = (player: Player | undefined) => {
    return (
      <div className="player-data">
        <ul className="ul">
          {renderItemPlayerData("Ranking", player?.order !== undefined ? player?.order + 1 : "-")}
          {renderItemPlayerData("Apodo", player?.nickname || "-")}
          {renderItemPlayerData("Edad", calculateAge(player?.date_of_birth) || "-")}
          {renderItemPlayerData(
            "Mano hábil",
            !player?.skillful_hand ? "-" : player?.skillful_hand === "L" ? "Izq" : "Der"
          )}
        </ul>
      </div>
    );
  };

  const renderPlayerDataPhone = (
    player1: PlayerH2H | undefined,
    player2: PlayerH2H | undefined
  ) => {
    return (
      <div className="player-data">
        <ul className="ul">
          {renderItemPlayerDataPhone(
            player1?.player.order !== undefined ? player1?.player.order + 1 : "-",
            "Ranking",
            player2?.player.order !== undefined ? player2?.player.order + 1 : "-"
          )}
          {renderItemPlayerDataPhone(
            player1?.player.nickname || "-",
            "Apodo",
            player2?.player.nickname || "-"
          )}
          {renderItemPlayerDataPhone(
            calculateAge(player1?.player.date_of_birth) || "-",
            "Edad",
            calculateAge(player2?.player.date_of_birth) || "-"
          )}
          {renderItemPlayerDataPhone(
            !player1?.player.skillful_hand
              ? "-"
              : player1?.player.skillful_hand === "L"
              ? "Izq"
              : "Der",
            "Mano hábil",
            !player2?.player.skillful_hand
              ? "-"
              : player2?.player.skillful_hand === "L"
              ? "Izq"
              : "Der"
          )}
          <div className="line-phone"></div>
          {renderItemPlayerDataPhone(
            player1?.player.order !== undefined ? player1?.matches_won : "-",
            "Resultado partidos",
            player2?.player.order !== undefined ? player2?.matches_won : "-"
          )}
          {renderItemPlayerDataPhone(
            player1?.player.order !== undefined ? player1?.games_won : "-",
            "Games frente a frente",
            player2?.player.order !== undefined ? player2?.games_won : "-"
          )}
          {renderItemPlayerDataPhone(
            player1?.player.order !== undefined
              ? `${player1?.career_matches_win} / ${player1?.career_matches_lost}`
              : "-",
            "Carrera W/L",
            player2?.player.order !== undefined
              ? `${player2?.career_matches_win} / ${player2?.career_matches_lost}`
              : "-"
          )}
        </ul>
      </div>
    );
  };

  // const renderTeamData = (team: Team | undefined) => {
  //   return (
  //     <div className="player-data">
  //       <ul className="ul">
  //         <li className="li">
  //           <span className="info"> Ranking team</span>
  //           <span className="value">{team?.id || "-"}</span>
  //         </li>
  //         <li className="li">
  //           <span className="info">Apodo team</span>
  //           <span className="value">{team?.name || "-"}</span>
  //         </li>
  //         <li className="li">
  //           <span className="info">Edad team</span>
  //           <span className="value">{team?.club?.name || "-"}</span>
  //         </li>
  //         <li className="li">
  //           <span className="info">Mano hábil team</span>
  //           <span className="value">{team?.club?.number_of_teams || "-"}</span>
  //         </li>
  //       </ul>
  //     </div>
  //   );
  // };

  const renderBarProgres = (value1?: number, value2?: number) => {
    const total = (value1 || 0) + (value2 || 0) + 2;
    return (
      <div className="bar-progress bar-progress-teams">
        <div
          className="bar-one bar-one-teams"
          style={{ width: `${(((value1 || 0) + 1) * 100) / total}%` }}
        ></div>
        <div
          className="bar-two bar-two-teams"
          style={{ width: `${(((value2 || 0) + 1) * 100) / total}%` }}
        ></div>
      </div>
    );
  };

  const renderSharedPlayerData = (
    playerOne: PlayerH2H | undefined,
    playerTwo: PlayerH2H | undefined
  ) => {
    return (
      <div className="shared-data">
        <ul className="ul-shared-data">
          <li>
            <div className="li-shared-data li-shared-data-phone">
              <span className="shared-data-player">{playerOne?.games_won || 0}</span>
              <span className="shared-data-info">Games frente a frente</span>
              <span className="shared-data-opponent">{playerTwo?.games_won || 0}</span>
            </div>
            <div className="bar-progress">
              {renderBarProgres(playerOne?.games_won || 0, playerTwo?.games_won || 0)}
            </div>
          </li>
          <li>
            <div className="li-shared-data li-shared-data-phone">
              <span className="shared-data-player">{`${playerOne?.career_matches_win || 0}/${
                playerOne?.career_matches_lost || 0
              }`}</span>
              <span className="shared-data-info">Carrera W/L</span>
              <span className="shared-data-opponent">{`${playerTwo?.career_matches_win || 0}/${
                playerTwo?.career_matches_lost || 0
              }`}</span>
            </div>
            <div className="bar-progress">
              {renderBarProgres(
                playerOne?.career_matches_win || 0,
                playerTwo?.career_matches_win || 0
              )}
            </div>
          </li>
        </ul>
      </div>
    );
  };

  const renderSharedItemTeamData = (
    valueHome: string | number,
    label: string,
    valueAway: string | number
  ) => {
    const value1 = typeof valueHome === "string" ? Number(valueHome.split("/")[0]) : valueHome;
    const value2 = typeof valueAway === "string" ? Number(valueAway.split("/")[0]) : valueAway;
    return (
      <>
        <li className="li-shared-data li-shared-data-teams ">
          <span className="shared-data-player">{valueHome}</span>
          <span className="shared-data-info">{label}</span>
          <span className="shared-data-opponent">{valueAway}</span>
        </li>
        {renderBarProgres(value1 || 0, value2 || 0)}
      </>
    );
  };

  const renderSharedItemTeamDataPhone = (
    value1: string | number,
    label: string,
    value2: string | number
  ) => {
    return (
      <li className="li">
        <span className="value">{value1}</span>
        <span className="info">{label}</span>
        <span className="value">{value2}</span>
      </li>
    );
  };

  const renderSharedTeamData = (teamOne: TeamH2H | undefined, teamTwo: TeamH2H | undefined) => {
    return (
      <div className="shared-data">
        <ul className="ul-shared-data ul-shared-data-teams">
          {renderSharedItemTeamData(
            teamOne?.matches_won || 0,
            "Partidos frente a frente",
            teamTwo?.matches_won || 0
          )}
          {renderSharedItemTeamData(
            `${teamOne?.career_showdowns_win || 0}/${teamOne?.career_showdowns_lost || 0}`,
            "Carrera W/L",
            `${teamTwo?.career_showdowns_win || 0}/${teamTwo?.career_showdowns_lost || 0}`
          )}
          {renderSharedItemTeamData(
            teamOne?.tournaments_won || 0,
            "Torneos ganados",
            teamTwo?.tournaments_won || 0
          )}
        </ul>
      </div>
    );
  };

  const renderSharedTeamDataPhone = (
    teamOne: TeamH2H | undefined,
    teamTwo: TeamH2H | undefined
  ) => {
    return (
      <div className="player-data">
        <ul className="ul">
          {renderSharedItemTeamDataPhone(
            teamOne?.showdowns_won || 0,
            "Resultado encuentros",
            teamTwo?.showdowns_won || 0
          )}
          {renderSharedItemTeamDataPhone(
            teamOne?.matches_won || 0,
            "Partidos frente a frente",
            teamTwo?.matches_won || 0
          )}
          {renderSharedItemTeamDataPhone(
            `${teamOne?.career_showdowns_win || 0}/${teamOne?.career_showdowns_lost || 0}`,
            "Carrera W/L",
            `${teamTwo?.career_showdowns_win || 0}/${teamTwo?.career_showdowns_lost || 0}`
          )}
          {renderSharedItemTeamDataPhone(
            teamOne?.tournaments_won || 0,
            "Torneos ganados",
            teamTwo?.tournaments_won || 0
          )}
        </ul>
      </div>
    );
  };

  const query = type === "player" ? playerQuery : teamQuery;

  useEffect(() => {
    if (!modelOne) setModelOneId(0);
    if (!modelTwo) setModelTwoId(0);
  }, [type, modelOne, modelTwo]);

  useEffect(() => {
    setModelOneId(Number(modelOne));
    setModelTwoId(Number(modelTwo));
  }, [modelOne, modelTwo]);

  return (
    <div className="page-container h2h-page-container">
      <HeaderH2H
        modelOne={type === "player" ? playerQuery.data?.player_one : teamQuery.data?.team_one}
        modelTwo={type === "player" ? playerQuery.data?.player_two : teamQuery.data?.team_two}
        onModelsSelected={handleModelsSelect}
        type={type}
        fetching={query.isFetching}
      />
      <div>
        <div className="h2h-data">
          {type === "player" && query.isFetching ? (
            <div className="loader-player-data">
              <Loader inverse speed="slow" size="lg" />
            </div>
          ) : type === "player" ? (
            renderPlayerData(playerQuery?.data?.player_one?.player)
          ) : (
            <div></div>
          )}

          {type === "player"
            ? renderSharedPlayerData(playerQuery?.data?.player_one, playerQuery?.data?.player_two)
            : renderSharedTeamData(teamQuery?.data?.team_one, teamQuery?.data?.team_two)}

          {type === "player" && query.isFetching ? (
            <div className="loader-player-data">
              <Loader inverse speed="slow" size="lg" />
            </div>
          ) : type === "player" ? (
            renderPlayerData(playerQuery?.data?.player_two?.player)
          ) : (
            <div></div>
          )}
        </div>
        <div className="h2h-data-phone">
          {type === "player" && query.isFetching ? (
            <div className="loader-player-data">
              <Loader inverse speed="slow" size="lg" />
            </div>
          ) : type === "player" ? (
            renderPlayerDataPhone(playerQuery?.data?.player_one, playerQuery?.data?.player_two)
          ) : (
            renderSharedTeamDataPhone(teamQuery?.data?.team_one, teamQuery?.data?.team_two)
          )}

          {type === "player"
            ? renderSharedPlayerData(playerQuery?.data?.player_one, playerQuery?.data?.player_two)
            : renderSharedTeamData(teamQuery?.data?.team_one, teamQuery?.data?.team_two)}
        </div>

        {query?.data && (
          <div className="event-breakdown">
            <h2>Últimos enfrentamientos</h2>
            <Table
              autoHeight
              data={query?.data?.last_results || []}
              headerHeight={56}
              rowHeight={44}
              className="custom-table"
              loadAnimation
              loading={query.isFetching}
              locale={{
                emptyMessage: "No se encontraron resultados",
                loading: "Cargando...",
              }}
            >
              <Column minWidth={130} flexGrow={1} align="left">
                <HeaderCell>Fecha</HeaderCell>
                <Cell dataKey="date" />
              </Column>
              <Column width={40} align="center">
                <HeaderCell>{""}</HeaderCell>
                <ImageCell dataKey="logo" />
              </Column>
              <Column minWidth={160} flexGrow={2}>
                <HeaderCell>Ganador</HeaderCell>
                <Cell dataKey="name" />
              </Column>
              <Column width={200} minWidth={120} flexGrow={2} align="left">
                <HeaderCell>Torneo</HeaderCell>
                <Cell dataKey="tournament" />
              </Column>
              <Column width={120}>
                <HeaderCell>Sede</HeaderCell>
                <Cell dataKey="localia" />
              </Column>
              <Column width={110} align="center">
                <HeaderCell align="right">Resultado</HeaderCell>
                <Cell dataKey="result" />
              </Column>
            </Table>
            <br />
            <br />
          </div>
        )}
      </div>
    </div>
  );
};

export default H2H;
