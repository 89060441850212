import { useQuery } from "@tanstack/react-query";
import { getGeneralStats } from "../../../../services/stats";
import { CACHE_TIME_1_HOUR } from "../../../../utils/constants";
import "./stats.less";

const Stats = () => {
  const { data, isLoading } = useQuery(["stats"], getGeneralStats, {
    staleTime: CACHE_TIME_1_HOUR,
  });

  const renderStat = (label: string, value: number | undefined) => {
    if (!value || isLoading) return null;

    return (
      <div className="stats-content-number-container">
        <h1 className="stats-content-number">{value}</h1>
        <h5 className="stats-content-label">{label}</h5>
      </div>
    );
  };

  return (
    <div className="stats-container">
      <div className="stats-container-background" />
      <div className="stats-content">
        <h1 className="stats-content-title">Estadísticas</h1>
        <h4 className="stats-content-subtitle">Conocé todas las estadísticas del torneo</h4>
        <div className="stats-content-numbers-container">
          {renderStat("Clubes", data?.number_of_clubs)}
          {renderStat("Equipos", data?.number_of_teams)}
          {renderStat("Torneos", data?.number_of_tournaments)}
          {renderStat("Partidos", data?.number_of_matchs)}
        </div>
      </div>
    </div>
  );
};

export default Stats;
