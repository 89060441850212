import { useTournaments } from "../../hooks/useTournaments";
import { Tournament } from "../../interfaces/tournament";
import "./tournaments.less";
import TournamentCard from "../../components/TournamentCard/TournamentCard";
import { Col, Grid, Row, Placeholder } from "rsuite";

const Tournaments = () => {
  const { activeTournaments, completedTournaments, isLoading } = useTournaments();

  const renderTournaments = (tournaments: Tournament[]) => {
    if (isLoading) {
      return (
        <Grid style={{ marginLeft: 0}}>
          <Row gutter={24}>
            <Col xs={24} md={8}>
              <Placeholder.Graph active height={280} />
            </Col>
            <Col xs={24} md={8}>
              <Placeholder.Graph active height={280} />
            </Col>
            <Col xs={24} md={8}>
              <Placeholder.Graph active height={280} />
            </Col>
          </Row>
        </Grid>
      );
    }

    if (tournaments.length === 0) {
      return (
        <div className="tournaments-no-results">
          <h3 className="tournaments-no-results-text">No encontramos torneos :(</h3>
        </div>
      );
    }

    return (
      <div className="tournaments-container">
        {tournaments.map((tournament) => (
          <TournamentCard tournament={tournament} />
        ))}
      </div>
    );
  };

  return (
    <div className="page-container">
      <h2 className="tournaments-status-title">En juego</h2>
      {renderTournaments(activeTournaments || [])}
      <h2 className="tournaments-status-title">Torneos anteriores</h2>
      {renderTournaments(completedTournaments || [])}
    </div>
  );
};

export default Tournaments;
