import { useEffect, useState, useMemo, useRef } from "react";
import { Journey } from "../../../../interfaces/journey";
import { useQuery } from "@tanstack/react-query";
import { CACHE_TIME_10_MINUTES, CACHE_TIME_1_HOUR } from "../../../../utils/constants";
import {
  getTournamentJourneyShowdowns,
  getTournamentJourneys,
} from "../../../../services/tournament";

export const useFixture = (tournamentId: number, onChangeJourney: (journeyId: number) => void, actualJourneyId?: number) => {
  const [activePage, setActivePage] = useState(1);
  const prevPagination = useRef({ numberOfShowdowns: 0, numberOfJourneys: 0 });
  const [currentJourney, setCurrentJourney] = useState<Journey | null>(null);
  const journeysQuery = useQuery(
    ["tournaments", tournamentId, "journeys"],
    () => getTournamentJourneys(tournamentId),
    {
      staleTime: CACHE_TIME_1_HOUR,
      enabled: !!tournamentId,
    }
  );
  const fixtureQuery = useQuery(
    ["tournaments", tournamentId, "fixture", currentJourney?.id],
    () => getTournamentJourneyShowdowns(tournamentId, currentJourney!.id),
    {
      enabled: !!currentJourney?.id,
      staleTime: CACHE_TIME_10_MINUTES,
    }
  );

  const onChangePage = (page: number) => {
    const journeys = journeysQuery.data || [];
    if (page < 1 || page > journeys.length) return;
    setActivePage(page);
    setCurrentJourney(journeys[page - 1]);
  };

  const pagination = useMemo(() => {
    const journeys = journeysQuery.data || [];
    const showdowns = fixtureQuery.data || [];
    const prevValue = prevPagination.current;
    const value = {
      numberOfShowdowns: showdowns.length === 0 ? prevValue.numberOfShowdowns : showdowns.length,
      numberOfJourneys: journeys.length,
    };
    prevPagination.current = value;
    return value;
  }, [journeysQuery.data, fixtureQuery.data]);

  useEffect(() => {
    if (journeysQuery.data && journeysQuery.data.length > 0) {
      if (actualJourneyId) {
        const journeyIdx = journeysQuery.data.findIndex((j) => j.id === actualJourneyId);
        if (journeyIdx >= 0) {
          setCurrentJourney(journeysQuery.data[journeyIdx]);
          setActivePage(journeyIdx + 1);
        } else setCurrentJourney(journeysQuery.data[0]);
      } else {
        setCurrentJourney(journeysQuery.data[0]);
      }
    }
  }, [journeysQuery.data, actualJourneyId]);

  useEffect(() => {
    if(currentJourney) {
      onChangeJourney(currentJourney.id)
    }
  }, [currentJourney, onChangeJourney])

  return {
    pagination,
    activePage,
    currentJourney,
    fixtureQuery,
    onChangePage,
  };
};
