import { useQuery } from "@tanstack/react-query";
import { Table } from "rsuite";
import { leaderboardProps } from "./types";
import "./leaderboard.less";
import { getTournamentLeaderboard } from "../../../../services/tournament";
import { CACHE_TIME_1_HOUR } from "../../../../utils/constants";
import { LeaderboardTeam } from "../../../../interfaces/leaderboard";
const { Column, HeaderCell, Cell } = Table;

const columns = [
  ["showdowns_won", "FG"],
  ["showdowns_lost", "FP"],
  ["matches_won", "PG"],
  ["matches_lost", "PP"],
  ["games_won", "GG"],
  ["games_lost", "GP"],
];

const Leaderboard = ({ tournamentId }: leaderboardProps) => {
  const query = useQuery(
    ["tournaments", tournamentId, "leaderboard"],
    () => getTournamentLeaderboard(tournamentId),
    {
      staleTime: CACHE_TIME_1_HOUR,
    }
  );

  const ImageCell = ({ rowData, dataKey, ...props }: any) => {
    const team = rowData as LeaderboardTeam;
    return (
      <Cell {...props} style={{ padding: 0 }}>
        <div className="leaderboard-club-logo-container">
          <img src={team.team?.club?.logo} className="leaderboard-club-logo" alt={team.team.name} />
        </div>
      </Cell>
    )
  };


  return (
    <div className="leaderboard-container">
      <h3 className="leaderboard-title">Posiciones</h3>
      <Table
        autoHeight
        data={(query.data || []).map((t, i) => ({ ...t, position: i + 1 }))}
        onRowClick={(rowData) => console.log(rowData)}
        headerHeight={56}
        rowHeight={44}
        className="custom-table"
        loadAnimation
        loading={query.isLoading}
        locale={{
          emptyMessage: "No se encontraron resultados",
          loading: "Cargando...",
        }}
      >
        <Column width={40} align="center" fixed>
          <HeaderCell>#</HeaderCell>
          <Cell dataKey="position" />
        </Column>
        <Column width={48} align="center" fixed>
          <HeaderCell>{""}</HeaderCell>
          <ImageCell dataKey="team.club.logo" />
        </Column>
        <Column minWidth={160} fixed flexGrow={1}>
          <HeaderCell>{""}</HeaderCell>
          <Cell dataKey="team.name" />
        </Column>
        <Column width={80}>
          <HeaderCell>Puntos</HeaderCell>
          <Cell dataKey="points" />
        </Column>
        {columns.map(([key, text]) => (
          <Column width={60} key={key}>
            <HeaderCell>{text}</HeaderCell>
            <Cell dataKey={key} />
          </Column>
        ))}
      </Table>
      <div className="leaderboard-description">
        <p><b>FG/FP: </b> Fechas ganadas/perdidas</p>
        <p><b>PG/PP: </b> Partidos ganados/perdidos</p>
        <p><b>GG/GP: </b> Games ganados/perdidos</p>
      </div>
    </div>
  );
};

export default Leaderboard;
