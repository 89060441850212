import React from "react";
import { Route, Routes } from "react-router-dom";
import "./styles/index.less";
import Home from "./pages/Home/Home";
import Tournament from "./pages/Tournament/Tournament";
import Tournaments from "./pages/Tournaments/Tournaments";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Clubs from "./pages/Clubs/Clubs";
import Club from "./pages/Club/Club";
import Rules from "./pages/Rules/Rules";
import H2H from "./pages/H2H/H2H";
import "dayjs/locale/es";
import dayjs from "dayjs";
import PlayerInfo from "./pages/PlayerInfo/PlayerInfo";
import TeamInfo from "./pages/TeamInfo/TeamInfo";

dayjs.locale("es");

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/torneos" element={<Tournaments />} />
        <Route path="/torneos/:id" element={<Tournament />} />
        <Route path="/clubes" element={<Clubs />} />
        <Route path="/clubes/:id" element={<Club />} />
        <Route path="/h2h/jugadores" element={<H2H type="player" />} />
        <Route path="/h2h/equipos" element={<H2H type="team" />} />
        <Route path="/jugadores/:id" element={<PlayerInfo />} />
        <Route path="/equipos/:id" element={<TeamInfo />} />
        <Route path="/reglamento" element={<Rules />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
