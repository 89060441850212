import { Showdown } from "../../../../interfaces/showdown";
import "./fixture.less";
import { FixtureProps } from "./types";
import { Pagination, Placeholder } from "rsuite";
import { useFixture } from "./useFixture";

const roundName = {
  OF: "Octavos de Final",
  QF: "Cuartos de Final",
  SF: "Semifinal",
  F: "Final",
};

type RoundNameType = keyof typeof roundName;

const Fixture = ({ tournamentId, journeyId, onChangeJourney, onShowdownClicked }: FixtureProps) => {
  const { pagination, activePage, currentJourney, fixtureQuery, onChangePage } = useFixture(
    tournamentId,
    onChangeJourney,
    journeyId
  );

  const renderPlaceholder = (idx: number) => {
    return (
      <div className="fixture-showdown" key={idx}>
        <Placeholder.Paragraph rowMargin={0} rows={1} active style={{ flex: 1 }} />
        <Placeholder.Paragraph
          rowMargin={0}
          rows={1}
          active
          style={{ width: 20, margin: "0 16px" }}
        />
        <Placeholder.Paragraph
          className="placeholder-align-right"
          rowMargin={16}
          rows={1}
          active
          style={{ flex: 1 }}
        />
      </div>
    );
  };

  const renderShowdown = (showdown: Showdown) => {
    if (fixtureQuery.isFetching) {
      return renderPlaceholder(0);
    }

    const { finished } = currentJourney || {};
    const { away, home, matchs_win_away, matchs_win_home } = showdown;
    const isFinished = finished || matchs_win_away > 0 || matchs_win_home > 0;

    const stopPropagation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
      e.stopPropagation();

    return (
      <div
        key={showdown.id}
        className={`fixture-showdown ${isFinished ? "completed" : ""}`}
        onClick={() => onShowdownClicked(showdown)}
      >
        <div className="fixture-team">
          <div className="team-logo-container">
            <img src={home?.club?.logo} className="team-logo" alt={home?.name} />
          </div>
          <p>{home?.name}</p>
        </div>
        <div className="fixture-result">
          <p className="fixture-showdown-result">
            {isFinished ? `${matchs_win_home || 0} - ${matchs_win_away || 0}` : "vs"}
          </p>
          {home && away && (
            <a
              className="fixture-showdown-h2h"
              href={`/h2h/equipos?home=${home.id}&away=${away.id}`}
              target="_blank"
              rel="noreferrer"
              onClick={stopPropagation}
            >
              Ver H2H
            </a>
          )}
        </div>
        <div className="fixture-team">
          <div className="team-logo-container">
            <img src={away?.club?.logo} className="team-logo" alt={away?.name} />
          </div>
          <p>{away?.name}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <h3 className="fixture-title">Fixture</h3>
      <div className="fixture-container">
        <div className="fixture-header">
          {fixtureQuery?.isLoading || fixtureQuery?.isFetching ? (
            <>
              <Placeholder.Paragraph rowMargin={10} rows={1} active style={{ width: 60 }} />
              <Placeholder.Paragraph
                rowMargin={0}
                rows={1}
                active
                style={{ width: 60 }}
                className="placeholder-align-right"
              />
            </>
          ) : (
            <>
              <h5>
                {currentJourney?.is_cup
                  ? roundName[currentJourney?.name as RoundNameType] || ""
                  : "Fecha " + currentJourney?.name}
              </h5>
              <h5>{currentJourney?.date}</h5>
            </>
          )}
        </div>

        <div className="fixture-content">
          {fixtureQuery?.isLoading
            ? Array.from(Array(pagination.numberOfShowdowns || 3).keys()).map(renderPlaceholder)
            : fixtureQuery.data?.map(renderShowdown)}
        </div>
      </div>
      <div className="pagination-container">
        <Pagination
          prev
          last
          next
          first
          size="md"
          total={pagination.numberOfJourneys * pagination.numberOfShowdowns}
          limit={pagination.numberOfShowdowns}
          activePage={activePage}
          onChangePage={onChangePage}
        />
      </div>
    </>
  );
};

export default Fixture;
