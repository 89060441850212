import "./clubs.less";
import { useQuery } from "@tanstack/react-query";
import { getClubs } from "../../services/clubs";
import { CACHE_TIME_1_HOUR } from "../../utils/constants";
import { Table } from "rsuite";
import { Club } from "../../interfaces/club";
import { useNavigate } from "react-router-dom";
const { Column, HeaderCell, Cell } = Table;

const Clubs = () => {
  const navigate = useNavigate();
  const query = useQuery(["clubs"], getClubs, {
    staleTime: CACHE_TIME_1_HOUR,
  });

  const handleRowClick = (club: Club) => navigate(`/clubes/${club.id}`);

  const ImageCell = ({ rowData, dataKey, ...props }: any) => (
    <Cell {...props} style={{ padding: 0 }}>
      <div className="clubs-logo-container">
        <img src={rowData.logo} className="clubs-logo" alt={rowData.name} />
      </div>
    </Cell>
  );

  return (
    <div className="page-container">
      <h2 className="clubs-title">Clubes</h2>
      <Table
        autoHeight
        data={query.data || []}
        onRowClick={(row) => handleRowClick(row as Club)}
        headerHeight={56}
        rowHeight={44}
        className="custom-table"
        loadAnimation
        loading={query.isLoading}
        locale={{
          emptyMessage: "No se encontraron resultados",
          loading: "Cargando...",
        }}
      >
        <Column width={48} align="center" fixed>
          <HeaderCell>{""}</HeaderCell>
          <ImageCell dataKey="logo" />
        </Column>
        <Column minWidth={160} fixed flexGrow={1}>
          <HeaderCell>Club</HeaderCell>
          <Cell dataKey="name" />
        </Column>
        <Column minWidth={160} flexGrow={1}>
          <HeaderCell>Dirección</HeaderCell>
          <Cell dataKey="address" />
        </Column>
        <Column width={120}>
          <HeaderCell>Equipos</HeaderCell>
          <Cell dataKey="number_of_teams" />
        </Column>
        <Column width={120}>
          <HeaderCell>Jugadores</HeaderCell>
          <Cell dataKey="number_of_players" />
        </Column>
      </Table>
      <br />
      <br />
    </div>
  );
};

export default Clubs;
