import { useEffect, useRef } from "react";
import { CupFormatLibray } from "../../../../interfaces/cup";
import "./cup.less";
import { transformData } from "./transformData";
import { CupProps } from "./types";
import { CACHE_TIME_1_HOUR } from "../../../../utils/constants";
import { useQuery } from "@tanstack/react-query";
import { getCupBrackets } from "../../../../services/tournament";
import { Placeholder } from "rsuite";

const viewer = (window as any).bracketsViewer;

const roundName = {
  OF: "Octavos de Final",
  QF: "Cuartos de Final",
  SF: "Semifinal",
  F: "Final",
};

type RoundNameType = keyof typeof roundName;

const Cup = ({ showTitle, tournamentId, onShowdownClicked }: CupProps) => {
  const firstRender = useRef(false);
  const query = useQuery(["tournaments", tournamentId, "cup"], () => getCupBrackets(tournamentId), {
    staleTime: CACHE_TIME_1_HOUR,
  });

  async function render(data: CupFormatLibray) {
    viewer.render({
      stages: data.stage,
      matches: data.match,
      matchGames: [],
      participants: data.participant,
    });
  }

  const handleBracketsUI = () => {
    const title = document.querySelector(".brackets-viewer .elimination h1") as HTMLHeadingElement;
    if (title) title.style.display = "none";
    handleRounds();
    handleMatches();
  };

  const handleRounds = () => {
    document.querySelectorAll(".rounds article h3").forEach((elem) => {
      const round = elem.parentElement as HTMLDivElement;
      const journeyId = round?.getAttribute("data-round-id");
      const { journeys } = query.data || {};
      const journey = journeys?.find((j) => j.id === Number(journeyId));
      if (journey) elem.textContent = roundName[journey.name as RoundNameType] || "-";
    });
  };

  const handleMatches = () => {
    document.querySelectorAll(".opponents").forEach((elem) => {
      const container = elem as HTMLDivElement;
      const logo = document.createElement("img");
      logo.style.height = "22px";
      logo.style.width = "auto";
      logo.style.marginTop = "0px";
      logo.style.marginRight = "10px";

      const logoAway = logo.cloneNode() as HTMLImageElement;
      container.style.fontSize = "14px";
      const home = container.childNodes[0] as HTMLDivElement;
      const away = container.childNodes[1] as HTMLDivElement;
      const homeId = home?.getAttribute("data-participant-id");
      const awayId = away?.getAttribute("data-participant-id");

      const { teams } = query.data || {};
      const homeTeam = teams?.find((t) => t.id === Number(homeId));
      const awayTeam = teams?.find((t) => t.id === Number(awayId));

      if (homeTeam) {
        const logoSrc = homeTeam.club?.logo;
        if (logoSrc) logo.src = logoSrc;
      }

      if (awayTeam) {
        const logoSrc = awayTeam.club?.logo;
        if (logoSrc) logoAway.src = logoSrc;
      }

      home.insertBefore(logo, home.childNodes[0]);
      away.insertBefore(logoAway, away.childNodes[0]);
    });

    document.querySelectorAll(".match").forEach((elem) => {
      const round = elem.parentElement as HTMLDivElement;
      const journeyId = round?.getAttribute("data-round-id");
      const showdownId = elem?.getAttribute("data-match-id");
      elem.addEventListener("click", () => {
        const { journeys } = query.data || {};
        const journey = journeys?.find((j) => j.id === Number(journeyId));
        if (journey) {
          const showdown = journey.showdowns?.find((s) => s.id === Number(showdownId));
          if (showdown) onShowdownClicked(showdown);
        }
      });
    });
  };

  useEffect(() => {
    if (!firstRender.current && !query.isLoading && query.data) {
      const cupData = transformData(query.data);
      render(cupData);
      firstRender.current = true;
      setTimeout(handleBracketsUI, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isLoading, query.data]);

  if (query.isLoading)
    return (
      <div className="page-container">
        <Placeholder.Paragraph style={{ margin: "50px 0" }} />
        <Placeholder.Grid rows={5} columns={4} active />
        <Placeholder.Paragraph style={{ margin: "50px 0" }} />
      </div>
    );

  return (
    <>
      {showTitle && <h3 className="cup-title">Copa</h3>}
      <div className="brackets-viewer"></div>
    </>
  );
};

export default Cup;
