import {
  CupFormatLibray,
  CupGroup,
  CupMatch,
  CupParticipant,
  CupRound,
  CupStage,
  MatchStatus,
} from "../../../../interfaces/cup";
import { CupTournament } from "../../../../interfaces/leaderboard";

export const transformData = (cupDb: CupTournament): CupFormatLibray => {
  const { id, name, teams, journeys } = cupDb;
  const participant: CupParticipant[] = teams.map((team) => ({
    id: team.id || 0,
    name: team.name,
    tournament_id: id,
  }));

  const stage: CupStage = {
    id: 1,
    number: 1,
    name: name,
    tournament_id: id,
    type: "single_elimination",
    settings: {
      size: participant.length,
      // seedOrdering: ["natural", "natural", "reverse_half_shift", "reverse"],
      seedOrdering: [],
      grandFinal: "single",
      matchesChildCount: 0,
    },
  };

  const group: CupGroup = {
    id: 1,
    stage_id: 1,
    number: 1,
  };

  const round: CupRound[] = journeys.map((journey) => ({
    id: journey.id,
    group_id: 1,
    stage_id: 1,
    number: journey.id,
  }));

  const match: CupMatch[] = journeys.reduce((acc, journey) => {
    const matches: CupMatch[] = journey.showdowns.map((showdown) => {
      const matchesCompleted = showdown.matchs?.filter(
        (match) => match.games_win_home !== undefined && match.games_win_away !== undefined
      );

      const homeWin = matchesCompleted?.filter((m) => m.games_win_home! > m.games_win_away!).length || 0;
      const awayWin = matchesCompleted?.filter((m) => m.games_win_away! > m.games_win_home!).length || 0;

      return {
        id: showdown.id,
        group_id: 1,
        stage_id: 1,
        round_id: journey.id,
        child_count: 0,
        number: journey.id,
        status: matchesCompleted.length === 5 ? MatchStatus.completed : MatchStatus.pending,
        opponent1: {
          id: showdown.home.id || 0,
          score: homeWin,
          result: homeWin > awayWin ? "win" : "loss",
        },
        opponent2: {
          id: showdown.away.id || 0,
          score: awayWin,
          result: awayWin > homeWin ? "win" : "loss",
        },
      };
    });

    return [...acc, ...matches];
  }, [] as CupMatch[]);

  return {
    participant,
    stage: [stage],
    group: [group],
    round,
    match,
  };
};
