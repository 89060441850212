import "./gallery.less";
import { Modal } from "rsuite";
import { useGallery } from "./useGallery";
import CloseIcon from "@rsuite/icons/Close";
import { GalleryProps } from "./types";
import { GalleryItem } from "../../../../interfaces/gallery";
import { getFileType } from "../../../../utils/file";

const Gallery = ({
  tournamentId,
  journeyId,
  showJourneysSelector,
  onChangeJourney,
}: GalleryProps) => {
  const {
    showFullScreen,
    imageIndexFullScreen,
    galleryQuery,
    journeysQuery,
    handleCloseFullScreen,
    handleImageClick,
  } = useGallery(tournamentId, journeyId, showJourneysSelector);

  const renderGalleryItem = (item: GalleryItem, index: number) => {
    const fileType = getFileType(item.file);
    let content = null;

    if (fileType === "video") {
      content = (
        <video className="gallery-item-content" controls>
          <source src={item.file} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    if (fileType === "image") {
      content = (
        <img
          className="gallery-item-content image"
          src={item.file}
          alt=""
          onClick={() => handleImageClick(index)}
        />
      );
    }

    if (!content) return null;

    return (
      <div className="gallery-item" key={item.id}>
        {content}
      </div>
    );
  };

  if (!showJourneysSelector && (!galleryQuery.data || galleryQuery.data.length === 0)) return null;

  return (
    <>
      <h3 className="gallery-title">Fotos y videos de la fecha</h3>
      {showJourneysSelector && journeysQuery?.data && journeysQuery.data.length > 0 && (
        <div className="gallery-pagination">
          {journeysQuery.data.map((journey) => (
            <div
              className={`gallery-pagination-item ${journey.id === journeyId && "selected"}`}
              key={journey.id}
              onClick={() => onChangeJourney && onChangeJourney(journey.id)}
            >
              {journey.name}
            </div>
          ))}
        </div>
      )}
      <div className="gallery-container">{galleryQuery.data?.map(renderGalleryItem)}</div>
      <Modal
        open={showFullScreen}
        onClose={handleCloseFullScreen}
        backdrop
        size="full"
        className="full-screen-modal"
      >
        <CloseIcon className="full-screen-close" onClick={handleCloseFullScreen} />
        <Modal.Body className="full-screen-content">
          <img
            className="image-full-screen"
            alt=""
            src={galleryQuery.data?.[imageIndexFullScreen]?.file}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Gallery;
