import { LastResultsProps } from "./types";
import dayjs from "dayjs";
import { Table } from "rsuite";
import { useNavigate } from "react-router-dom";

import { LastResultTable } from "../../../../interfaces/playerStats";
const { Column, HeaderCell, Cell } = Table;

export const LastResults = ({ teamId, isFetching, lastResults }: LastResultsProps) => {
  const navigate = useNavigate();

  const ImageCell = ({ rowData, dataKey, ...props }: any) => (
    <Cell {...props} style={{ padding: 0 }}>
      <div className="clubs-logo-container">
        {rowData.logo && <img src={rowData.logo} className="clubs-logo" alt={rowData.name} />}
      </div>
    </Cell>
  );

  const data: LastResultTable[] = (lastResults || []).map((result) => {
    const rival = teamId === result?.away?.id ? result?.home : result?.away;
    const matchsRival =
      teamId === result?.away?.id ? result?.matchs_win_home : result?.matchs_win_away;
    const matchsHome =
      teamId === result?.away?.id ? result?.matchs_win_away : result?.matchs_win_home;

    return {
      id: rival.id,
      date: dayjs(result.date).format("D MMM, YYYY"),
      name: rival.name,
      logo: rival.club?.logo,
      tournament: result.tournament_name,
      result: `${matchsHome}-${matchsRival}`,
    };
  });

  return (
    <div>
      <br />
      <br />
      <br />
      <h2>Últimos enfrentamientos</h2>
      <Table
        autoHeight
        data={data}
        headerHeight={56}
        rowHeight={44}
        className="custom-table"
        loadAnimation
        loading={isFetching}
        onRowClick={(rowData) => navigate("/equipos/" + rowData.id)}
        locale={{
          emptyMessage: "No se encontraron resultados",
          loading: "Cargando...",
        }}
      >
        <Column minWidth={130} flexGrow={1} align="left">
          <HeaderCell>Fecha</HeaderCell>
          <Cell dataKey="date" />
        </Column>
        <Column width={40} align="center">
          <HeaderCell>{""}</HeaderCell>
          <ImageCell dataKey="logo" />
        </Column>
        <Column minWidth={160} flexGrow={2}>
          <HeaderCell>Rival</HeaderCell>
          <Cell dataKey="name" />
        </Column>
        <Column width={200} minWidth={120} flexGrow={2} align="left">
          <HeaderCell>Torneo</HeaderCell>
          <Cell dataKey="tournament" />
        </Column>

        <Column width={110} align="center">
          <HeaderCell align="right">Resultado</HeaderCell>
          <Cell dataKey="result" />
        </Column>
      </Table>
      <br />
      <br />
    </div>
  );
};
