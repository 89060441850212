import { AutocompleteProps } from "./types";
import { Loader } from "rsuite";
import { Close, Search } from "@rsuite/icons";
import useAutoComplete from "./useAutocomplete";
import "./autocomplete.less";

const Autocomplete = ({
  searching,
  placeholder,
  containerStyles,
  options,
  value,
  onSearch,
  onSelect,
  renderListItem,
  renderSelectedItem,
}: AutocompleteProps) => {
  const { bindInput, bindOptions, searchMode, handleSearchMode, handleSelectOption } =
    useAutoComplete({
      delay: 500,
      options,
      value,
      onSelect,
      onSearch,
    });

  return (
    <div className="autocomplete-container" style={containerStyles}>
      <div className="autocomplete-input-container">
        <div className="autocomplete-content">
          {(searchMode || !value) && (
            <input
              {...bindInput}
              placeholder={placeholder || "Buscar"}
              className="autocomplete-input"
            />
          )}
          {!searchMode && value && (
            <div className="autocomplete-selected-item-container" onClick={handleSearchMode(true)}>
              {renderSelectedItem ? (
                renderSelectedItem(value)
              ) : (
                <div className="autocomplete-selected-item">{value.name}</div>
              )}
            </div>
          )}
        </div>
        <div className="autocomplete-icon-container">
          {searching && <Loader size="sm" />}
          {!searching && searchMode && (value || bindInput.value) && (
            <Close className="autocomplete-close" onClick={handleSearchMode(false)} />
          )}
          {!searching && value && !searchMode && (
            <Search className="autocomplete-search" onClick={handleSearchMode(true)} />
          )}
        </div>
      </div>
      {searchMode && (
        <div {...bindOptions} className="autocomplete-list-container">
          {options.map((option, index) => (
            <li className="autocomplete-list-item" key={index} onClick={handleSelectOption(option)}>
              {renderListItem ? (
                renderListItem(option)
              ) : (
                <div className="autocomplete-list-item-content">{option.name}</div>
              )}
            </li>
          ))}
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
