import dayjs from "dayjs";
import { Club, ClubDetail } from "../interfaces/club";
import { api } from "./api";

export const getClubs = async () => {
  const { data } = await api.get<Club[]>(`/api/v1/clubs/`);
  return data;
};

export const getClub = async (id: number): Promise<ClubDetail> => {
  const { data } = await api.get<ClubDetail>(`/api/v1/clubs/${id}/`);
  return {
    ...data,
    players: data.players?.map((p) => ({
      ...p,
      date_of_birth: p.date_of_birth ? dayjs(p.date_of_birth).format("DD/MM/YYYY") : "",
    })),
    teams: data.teams?.map((t) => ({
      ...t,
      captain: t.captain
        ? {
            ...t.captain,
            fullname: `${t.captain.first_name} ${t.captain.last_name}`,
          }
        : undefined,
    })),
  };
};
